import React from 'react';
import { Col } from 'react-bootstrap';
import {
  getClassificatorValue, ReportTemplate, ReportTemplatePrefix
} from 'interfaces';
import FormFieldControl from 'components/FormFieldControl';
import { CompanyMap, FieldProps } from '../interfaces';

const TypeField: React.FC<FieldProps> = ({ audit }) => {
  const { type } = audit;

  return (
    <Col>
      <FormFieldControl name="type" defaultValue={type} />
    </Col>
  );
};

const type: CompanyMap = {
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix.HASHEX)]: () => null,
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix['0XGUARD'])]: TypeField,
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix.RUGDOG)]: TypeField
};

export default type;
