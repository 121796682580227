import React, {
  useState, useEffect, useContext, createContext,
} from 'react';

import { Profile } from 'interfaces';

import Loader from 'components/Loader';
import { useBackend } from './BackendProvider';

export const UserContext = createContext<Profile | null>(null);

export const useUser = () => useContext(UserContext);

const useFetchUser = () => {
  const { authService } = useBackend();
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const profile = await authService.profile();
      profile.isAdmin = profile.roles.split(';').includes('admin');
      setProfile(profile);
      setLoading(false);
    };

    fetchProfile();
  }, []);

  return {
    loading,
    profile,
  };
};

export const UserProvider: React.FC = (props) => {
  const { loading, profile } = useFetchUser();
  const { children } = props;

  return (
    <UserContext.Provider value={profile}>
      {loading ? <Loader /> : children}
    </UserContext.Provider>
  );
};
