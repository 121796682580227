import React from 'react';
import { Document, Page } from 'react-pdf';

interface PDFPreviewProps {
  file: any;
}

const PDFPreview: React.FC<PDFPreviewProps> = ({ file }) => {
  const onDocumentLoadSuccess = () => {
    console.log('loaded');
  };

  return (
    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
      <Page />
    </Document>
  );
};

export default PDFPreview;
