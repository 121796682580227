import React from 'react';
import classnames from 'classnames';

import { Chapter } from 'interfaces';

import List, { ListProps } from 'components/List';
import ChapterForm from 'forms/Chapter';

export interface ChaptersListProps
  extends Omit<ListProps<Chapter>, 'renderItem'> {}

const ChaptersList: React.FC<ChaptersListProps> = ({ className, ...props }) => {
  const classNames = classnames('chapters-list', className);
  return (
    <List<Chapter>
      className={classNames}
      {...props}
      component={ChapterForm}
      addText="Add chapter"
      sortable
    />
  );
};

export default ChaptersList;
