import ProtectedService from './ProtectedService';
import api from '../http';
import { accessTokenApi } from '../localStorage';

class AuthService extends ProtectedService {
  baseUrl = '/auth';

  constructor(
      public setProfileLoaded: () => void = () => {},
      public getAccessToken: () => string|null,
      public setAccessToken: (token: string) => void = () => {},
      public clearAccessToken: () => void = () => {}
  ) {
    super(getAccessToken, setAccessToken, clearAccessToken);
  }

  login = async (data: { login: string; pwd: string }) => {
    const url = this.getUrl('login');
    const response = await this.api.post(url, data, {
      headers: { Authorization: '' },
      withCredentials: true,
    });
    return response.data;
  };

  logout = async () => {
    const response = await this.get(null, 'logout');
    this.clearAccessToken();
    return response;
  };

  changePassword = async (data: {
    login: string;
    pwd: string;
    newPwd: string;
    confirmedNewPwd: string;
  }) => {
    const url = this.getUrl('change_pwd');
    const { ...result } = data;
    const response = await this.api.post(url, result, {
      headers: { Authorization: '' },
    });
    this.setAccessToken(response.data.access_token);
    return response;
  };

  profile = async () => {
    const response = await this.get(null, 'profile');
    this.setProfileLoaded();
    return response;
  };

  static checkAuth = async () => api.get('auth/profile', {
    headers: { Authorization: `Bearer ${accessTokenApi.get()}` },
  });
}

export default AuthService;
