import React from 'react';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsQuestionCircle } from 'react-icons/bs';
import './help.scss';

const Help = () => (
  <div className="help">
    <OverlayTrigger
      overlay={<Tooltip placement="right-end">Use the following key combination to save your time</Tooltip>}
    >
      <div className="d-inline-block mb-3">
        <h3 className="d-inline-block mb-0 help__tooltip-title">Hotkeys</h3>

        <BsQuestionCircle className="help__tooltip-icon" />
      </div>
    </OverlayTrigger>

    <Table striped className="help__hotkeys-table">
      <thead>
        <tr>
          <th>Page</th>
          <th>Key combination</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Audits</td>
          <td>Ctrl + A (Cmd + A)</td>
          <td>Add new audit</td>
        </tr>

        <tr>
          <td>Add and edit audit</td>
          <td>Ctrl + S (Cmd + S)</td>
          <td>Save</td>
        </tr>

        <tr>
          <td>Edit audit</td>
          <td>Ctrl + P (Cmd + P), Ctrl + 3 (Cmd + 3)</td>
          <td>Preview</td>
        </tr>

        <tr>
          <td>Edit audit</td>
          <td>Ctrl + D (Cmd + D), Ctrl + 4 (Cmd + 4)</td>
          <td>Download PDF</td>
        </tr>

        <tr>
          <td>Add and edit audit</td>
          <td>Ctrl + Shift + C (Cmd + Shift + C)</td>
          <td>Add contract</td>
        </tr>

        <tr>
          <td>All</td>
          <td>Ctrl + L (Cmd + L)</td>
          <td>Logout</td>
        </tr>
      </tbody>
    </Table>
  </div>
);

export default Help;
