import React from 'react';
import Page from 'containers/page';
import ForkCheckerPage from '../../components/ForkCheckerPage';

const ForkChecker = () => (
  <Page title="Fork checker">
    <ForkCheckerPage />
  </Page>
);

export default ForkChecker;
