import React from 'react';
import Page from 'containers/page';
import { Container } from 'react-bootstrap';

import StandardIssuesTable from '../../components/Table/StandardIssuesTable';

const StandardIssues = () => (
  <Page title="Standard Issues">
    <Container>
      <StandardIssuesTable />
    </Container>
  </Page>
);

export default StandardIssues;
