import React, { useState } from 'react';
import { FaRegSave } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Button from 'components/Button/index';
import { StandardIssue } from '../../../interfaces';
import Popup from '../../Popup/Popup';
import StandardIssueForm from '../../../forms/StandardIssue';
import { getErrorMessage } from '../../../helpers';
import { useBackend } from '../../../providers/BackendProvider';

const prepareIssue = (issue: any) => ({
  ...issue,
  description: JSON.stringify(issue.description),
});

interface CreateStandardIssueButtonProps {
  getDefault: () => {
    name?: string;
    description?: string;
    severity?: number;
  };
}

const CreateStandardIssueButton: React.FC<CreateStandardIssueButtonProps> = ({
  getDefault,
}) => {
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [isResponseLoading, setIsResponseLoading] = useState<boolean>(false);
  const [state, setState] = useState<StandardIssue | null>(null);
  const { standardIssuesService } = useBackend();

  const onOpen = (event: any) => {
    event.stopPropagation();
    setState({
      ...getDefault(),
      tags: [] as Array<any>,
    } as StandardIssue);
    setShowCreate(true);

    return false;
  };

  const onSubmit = async (data: any) => {
    if (isResponseLoading) return;
    setIsResponseLoading(true);
    try {
      await standardIssuesService.post(prepareIssue(data));
      toast.success('Issue saved');
      setShowCreate(false);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsResponseLoading(false);
    }
  };

  const onCreateClose = () => {
    if (isResponseLoading) return;
    setShowCreate(false);
  };

  return (
    <>
      <Button
        className="right-position-btn"
        size="sm"
        title="Save as standard"
        variant="dark"
        onClick={onOpen}
      >
        <FaRegSave />
      </Button>
      <Popup showPopup={showCreate} half>
        <StandardIssueForm
          state={state!}
          onSubmit={onSubmit}
          onCreateClose={onCreateClose}
          isSubmitting={isResponseLoading}
        />
      </Popup>
    </>
  );
};

export default CreateStandardIssueButton;
