import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { FaTimes } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Row, Col, CloseButton } from 'react-bootstrap';
import { getErrorMessage } from 'helpers';
import Button from 'components/Button/index';
import Popup from '../../Popup/Popup';
import { useBackend } from '../../../providers/BackendProvider';

const DeleteAuditButton: React.FC<{
  id: number;
  short?: boolean;
  updateAudits?: () => void;
}> = ({ id, short, updateAudits = () => {} }) => {
  const { auditService } = useBackend();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const deleteReport = useCallback(async () => {
    setLoading(true);
    try {
      await auditService.deleteAudit(id);
      if (history.location.pathname !== '/audits') {
        history.push('/audits');
      } else {
        await updateAudits();
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(false);
      setShowPopup(false);
    }
  }, [id, history.location.pathname]);
  const onPopupClose = () => setShowPopup(false);

  return (
    <>
      <Button
        size="sm"
        title={short ? 'Delete' : undefined}
        variant="danger"
        onClick={() => setShowPopup(true)}
        loading={loading}
      >
        <FaTimes />
        {' '}
        {!short ? 'Delete' : null}
      </Button>
      <Popup showPopup={showPopup}>
        <Row className="mb-4">
          <Col className="d-flex justify-content-end">
            <CloseButton onClick={onPopupClose} />
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <p className="h5">Are you sure you want to delete this report?</p>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-end">
            <Button className="btn-danger m-0" onClick={deleteReport}>
              Delete
            </Button>
          </Col>
        </Row>
      </Popup>
    </>
  );
};

export default DeleteAuditButton;
