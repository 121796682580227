import axios from 'axios';
import { API_BASE_URL, REFRESH_TOKEN_SUB_URL } from 'config';
import { accessTokenApi } from '../localStorage';
import { ErrorName } from '../interfaces';

const api = axios.create({
  baseURL: API_BASE_URL,
  cancelToken: axios.CancelToken.source().token,
});

let refreshTokenMutex = false;

api.interceptors.response.use((config) => config, async (error) => {
  const callWithUpdatedToken = async (originalConfig: any) => {
    // updating original request with new token
    const newConfig = originalConfig;
    newConfig.headers.Authorization = `Bearer ${accessTokenApi.get()}`;
    // sending original request with new bearer token
    return api.request(newConfig);
  };

  // silently update accessToken
  const updateToken = async () => {
    const response = await axios.post(REFRESH_TOKEN_SUB_URL, {}, {
      withCredentials: true,
      baseURL: API_BASE_URL
    });

    accessTokenApi.set(response.data.access_token);
  };

  if (error.response?.data?.error !== ErrorName.TokenExpiredError) {
    throw error;
  }

  if (refreshTokenMutex) {
    // waiting for token update
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!refreshTokenMutex) {
          clearInterval(interval);
          resolve(true);
        }
      }, 300);
    });
    return callWithUpdatedToken(error.config);
  }

  let err = null;
  try {
    refreshTokenMutex = true;
    await updateToken();
    return await callWithUpdatedToken(error.config);
  } catch (e) {
    err = e;
  } finally {
    refreshTokenMutex = false;
  }
  throw err;
});

export default api;
