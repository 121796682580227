import React, {
  createContext, useContext, useMemo, useState
} from 'react';
import IssuesService from '../services/IssuesService';
import AuthService from '../services/AuthService';
import AuditService from '../services/AuditService';
import ForkCheckerService from '../services/forkCheckerService';
import AutoAuditService from '../services/AutoAuditService';
import EnumsService from '../services/EnumsService';
import FileService from '../services/FileService';
import StandardIssuesService from '../services/StandardIssuesService';
import TemplatesService from '../services/TemplatesService';
import UsersService from '../services/UsersService';
import { accessTokenApi } from '../localStorage';
import LineCounterService from '../services/LineCounterService';

export const BackendContext = createContext<{
      profileLoaded: boolean;
      accessToken: string|null;
      issuesService: IssuesService;
      authService: AuthService;
      auditService: AuditService;
      forkCheckerService: ForkCheckerService;
      autoAuditService: AutoAuditService;
      enumsService: EnumsService;
      fileService: FileService;
      standardIssuesService: StandardIssuesService;
      templatesService: TemplatesService;
      usersService: UsersService;
      lineCounterService: LineCounterService;
        }>({
          profileLoaded: false,
          accessToken: null,
          // @ts-ignore
          issuesService: null,
        });

export const useBackend = () => useContext(BackendContext);

export const BackendProvider: React.FC = (props) => {
  const getAccessToken = useMemo(() => () => accessTokenApi.get(), []);
  const [accessToken, setNewAccessToken] = useState<string|null>(getAccessToken());
  const [profileLoaded, setNewProfileLoaded] = useState<boolean>(false);
  const setProfileLoaded = useMemo(() => () => {
    setNewProfileLoaded(true);
  }, []);
  const setAccessToken = useMemo(() => (token: string) => {
    accessTokenApi.set(token);
    setNewAccessToken(token);
  }, []);
  const clearAccessToken = useMemo(() => () => {
    accessTokenApi.remove();
    setNewAccessToken(null);
  }, []);

  // For services below it is important to use only singletons!
  // Otherwise, you need to extend deps array everywhere they are used.
  const issuesService = useMemo(() => new IssuesService(getAccessToken, setAccessToken, clearAccessToken), []);
  const authService = useMemo(() => new AuthService(setProfileLoaded, getAccessToken, setAccessToken, clearAccessToken), []);
  const fileService = useMemo(() => new FileService(getAccessToken, setAccessToken, clearAccessToken), []);
  const auditService = useMemo(() => new AuditService(fileService, getAccessToken, setAccessToken, clearAccessToken), []);
  const forkCheckerService = useMemo(() => new ForkCheckerService(getAccessToken, setAccessToken, clearAccessToken), []);
  const autoAuditService = useMemo(() => new AutoAuditService(getAccessToken, setAccessToken, clearAccessToken), []);
  const enumsService = useMemo(() => new EnumsService(getAccessToken, setAccessToken, clearAccessToken), []);
  const standardIssuesService = useMemo(() => new StandardIssuesService(getAccessToken, setAccessToken, clearAccessToken), []);
  const templatesService = useMemo(() => new TemplatesService(getAccessToken, setAccessToken, clearAccessToken), []);
  const usersService = useMemo(() => new UsersService(getAccessToken, setAccessToken, clearAccessToken), []);
  const lineCounterService = useMemo(() => new LineCounterService(getAccessToken, setAccessToken, clearAccessToken), []);

  const context = useMemo(() => ({
    profileLoaded,
    accessToken,
    issuesService,
    authService,
    auditService,
    forkCheckerService,
    autoAuditService,
    enumsService,
    fileService,
    standardIssuesService,
    templatesService,
    usersService,
    lineCounterService,
  }), [
    profileLoaded,
    accessToken
  ]);
  const { children } = props;

  return (
    <BackendContext.Provider value={context}>
      {children}
    </BackendContext.Provider>
  );
};
