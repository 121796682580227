import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import hashexLogo from 'images/logo/hashexLogo.svg';
import rugDogLogo from 'images/logo/rugDog.svg';
import GuardLogo from 'images/logo/0xGuard_logo.png';
import rapidLabsLogo from 'images/logo/rapidLabsLogo.svg';
import rugFreeLogo from 'images/logo/rugFreeLogo.svg';
import { useUser } from 'providers/UserProvider';
import './index.css';

const Header = () => {
  const profile = useUser();
  let logo;
  switch (profile?.company) {
    case 'HashEx':
      logo = hashexLogo;
      break;
    case 'RugDog':
      logo = rugDogLogo;
      break;
    case '0xGuard':
      logo = GuardLogo;
      break;
    case 'Rapid Labs':
      logo = rapidLabsLogo;
      break;
    case 'RugFree':
      logo = rugFreeLogo;
      break;
    default:
      break;
  }
  return (
    <Navbar>
      <Container>
        <Navbar.Brand as={Link} to="/" className="header-logo">
          <img src={logo} alt="" />
          {profile?.company}
          {' '}
          Auditor tool
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {profile ? (
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/audits">
                Audits
              </Nav.Link>
              <Nav.Link as={Link} to="/issues">
                Issues
              </Nav.Link>
              <Nav.Link as={Link} to="/standard-issues">
                Standard issues
              </Nav.Link>
              <Nav.Link as={Link} to="/audits/add">
                Add new audit
              </Nav.Link>
              <Nav.Link as={Link} to="/fork-checker">
                Fork checker
              </Nav.Link>
              <Nav.Link as={Link} to="/line-counter">
                Line Counter
              </Nav.Link>
              {profile.isAdmin ? (
                <Nav.Link as={Link} to="/admin">
                  Admin
                </Nav.Link>
              ) : null}
              <Nav.Link as={Link} to="/help">
                Help
              </Nav.Link>
            </Nav>
          ) : null}

          <Navbar.Text>
            {profile ? (
              <>
                Signed in as:
                {' '}
                <Link to="/user/profile">{profile.name}</Link>
              </>
            ) : null}
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
