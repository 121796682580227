import ProtectedService from './ProtectedService';
import { ContractDataDto } from '../dtos/contract-data.dto';
import { CounterResultDto } from '../dtos/counter-result.dto';

class LineCounterService extends ProtectedService {
    baseUrl = '/line-counter';

    countLines = async (contractData: ContractDataDto): Promise<CounterResultDto> => this.post(
      contractData,
    );
}

export default LineCounterService;
