import React, { useState, useEffect } from 'react';
import { AutoAuditsInTable } from 'interfaces';
import { useUser } from 'providers/UserProvider';
import AutoAuditsTable from './AutoAuditsTable';
import { useBackend } from '../../../providers/BackendProvider';

const Container: React.FC<{}> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [audits, setAudits] = useState<AutoAuditsInTable[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [filter, setFilter] = useState<any>({});
  const [limit, setLimit] = useState<number>(20);
  const [page, setPage] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>('dateTimeStamp');
  const [sortDirection, setSortDirection] = useState<string>('desc');
  const user = useUser();
  const { autoAuditService } = useBackend();

  const fetchAudits = async (
    nPage: number = page,
    nLimit: number = limit,
    nSortBy: string = sortBy,
    nSortDirection: string = sortDirection,
    nFilter: any = filter,
  ) => {
    if (user?.id) {
      setLoading(true);

      try {
        const [newAudits, count] = await autoAuditService.getList(
          nPage,
          nLimit,
          nSortBy,
          nSortDirection,
          nFilter,
        );

        setAudits(newAudits);
        setPageCount(Math.ceil(count / nLimit));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const changeSort = (sort: string, direction: string) => {
    setSortBy(sort);
    setSortDirection(direction);
    fetchAudits(page, limit, sort, direction);
  };

  let filterInputTimeout: any;

  const onFilterChange = (
    field: string,
    value: any,
    needTimeout: boolean = false,
  ) => {
    if (needTimeout && filterInputTimeout) clearTimeout(filterInputTimeout);
    filterInputTimeout = setTimeout(() => {
      const nFilter = {
        ...filter,
        [field]: value,
      };
      setPage(0);
      setFilter(nFilter);
      fetchAudits(0, limit, sortBy, sortDirection, nFilter);
    }, 500);
  };

  const handlePageClick = (e: { selected: number }) => {
    setPage(e.selected);
    fetchAudits(e.selected);
  };

  const onLimitChange = (value: number) => {
    setPage(0);
    setLimit(value);
    fetchAudits(0, value);
  };

  useEffect(() => {
    fetchAudits();
  }, []);

  return (
    <AutoAuditsTable
      loading={loading}
      audits={audits}
      fetchAudits={fetchAudits}
      sortBy={sortBy}
      sortDirection={sortDirection}
      changeSort={changeSort}
      onFilterChange={onFilterChange}
      page={page}
      pageCount={pageCount}
      handlePageClick={handlePageClick}
      limit={limit}
      onLimitChange={onLimitChange}
    />
  );
};

export default Container;
