import React, { useMemo } from 'react';
import Select, { SelectProps } from './Select';
import { EnumTypes } from '../../services/EnumsService';
import { useEnums } from '../../providers/EnumsProvider';

type EnumSelectProps = SelectProps & { type: EnumTypes };

// eslint-disable-next-line react/display-name
const EnumSelect: React.FC<EnumSelectProps> = React.forwardRef(
  ({
    type, multiple, multipleProp, ...props
  }, ref) => {
    const { filterEnums, enums } = useEnums();

    const options = useMemo(
      () => filterEnums(type).map((item: any) => ({
        value: item.key,
        label: item.value,
      })),
      [enums],
    );
    return (
      <Select
        {...props}
        options={options}
        multiple={multiple}
        multipleProp={multipleProp}
        ref={ref}
      />
    );
  },
);

export default EnumSelect;
