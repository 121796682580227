import React from 'react';

import { SelectProps } from './Select';
import { EnumTypes } from '../../services/EnumsService';
import EnumSelect from './EnumSelect';

// eslint-disable-next-line react/display-name
const AuditStatusSelect: React.FC<SelectProps> = React.forwardRef(
  (props, ref) => (
    <EnumSelect {...props} type={EnumTypes.AUDIT_STATUS} ref={ref} />
  ),
);

export default AuditStatusSelect;
