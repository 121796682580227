import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Page from 'containers/page';

import AuditsTable from 'components/Table/AuditsTable';

const Audits = () => {
  const history = useHistory();

  const onKeydown = (e: KeyboardEvent) => {
    if (e.code === 'KeyA' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      history.push('/audits/add');
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);

    return () => document.removeEventListener('keydown', onKeydown);
  }, []);

  return (
    <Page title="Audits">
      <AuditsTable />
    </Page>
  );
};

export default Audits;
