import React from 'react';
import {
  Diff
// @ts-ignore
} from 'react-diff-view';
import './variables.css';
import './visual-studio.css';

export interface DiffComponentProps {
    diff: any
}

const DiffComponent: React.FC<DiffComponentProps> = ({ diff }) => (
  <div className="diff-wrapper">
    <Diff
      viewType="split"
      diffType={diff.type}
      hunks={diff.hunks || []}
      tokens={diff.tokens}
      optimizeSelection
    />
  </div>

);

export default DiffComponent;
