import React from 'react';
import { useFormContext } from 'components/Form/context';

// eslint-disable-next-line react/display-name
const IsStandardIssueCheckbox: React.FC<any> = React.forwardRef<HTMLInputElement, { name: string }>((props, ref) => {
  const { name } = props;
  const { register } = useFormContext();

  return (
    <>
      <input type="checkbox" ref={ref} {...register(name)} className="checkbox" id="standard" />

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="standard">
        <b>Is standard</b>
      </label>
    </>
  );
});

export default IsStandardIssueCheckbox;
