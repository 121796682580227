import React from 'react';

import './Slider.css';
import Slider, { SliderProps } from 'rc-slider';

const SliderComponent: React.FC<SliderProps<number | number[]>> = ({
  onChange, value, min, max, ...props
}) => (
  <Slider
    min={min}
    max={max}
    value={value}
    onChange={onChange}
    marks={{ [min!]: min, [max!]: max }}
    {...props}
  />
);

export default SliderComponent;
