import React from 'react';
import { Form } from 'react-bootstrap';
import { ControllerProps } from 'react-hook-form';

import Input from 'components/Input';

import './FormGroup.css';

export interface FormGroupProps extends Omit<ControllerProps, 'render'> {
  label?: string;
  help?: string;
  error?: string;
  component?: any;
  name: `${string}`;
  type?: string;
}

// eslint-disable-next-line react/display-name
const FormGroup: React.FC<FormGroupProps> = React.forwardRef(
  ({
    label, component = Input, help, error, ...props
  }, ref) => {
    const Component = component;
    return (
      <Form.Group className="form-group">
        {label && <Form.Label>{label}</Form.Label>}
        <Component {...props} ref={ref} isInvalid={!!error} />
        {help && <Form.Text>{help}</Form.Text>}
        {error && (
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        )}
      </Form.Group>
    );
  },
);

export default FormGroup;
