import React from 'react';
import Page from 'containers/page';
import HelpPage from 'components/Help';

const Help = () => (
  <Page title="Help">
    <HelpPage />
  </Page>
);

export default Help;
