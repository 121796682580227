import BaseService from './BaseService';

class ProtectedService extends BaseService {
  constructor(
    public getAccessToken: () => string|null,
    public setAccessToken: (token: string) => void = () => {},
    public clearAccessToken: () => void = () => {}
  ) {
    super();
  }

  makeProtectedRequest = async (fn: any) => {
    try {
      const Authorization = this.getAuthHeader();
      if (!Authorization) throw new Error('No Authorization');
      const authHeaders = { Authorization };
      return await fn(authHeaders);
    } catch (e) {
      if (e.response?.status === 401) {
        this.clearAccessToken();
      }

      throw e;
    }
  };

  get = async (params: any = {}, subUrl?: string, headers?: any) => this.makeProtectedRequest(
    async (authHeaders: any) => super.get(params, subUrl, {
      ...authHeaders,
      ...headers,
    }),
  );

  post = async (params: any = {}, subUrl?: string, headers?: any) => this.makeProtectedRequest(
    async (authHeaders: any) => super.post(params, subUrl, {
      ...authHeaders,
      ...headers,
    }),
  );

  patch = async (params: any = {}, subUrl?: string, headers?: any) => this.makeProtectedRequest(
    async (authHeaders: any) => super.patch(params, subUrl, {
      ...authHeaders,
      ...headers,
    }),
  );

  delete = async (params: any = {}, subUrl: string, headers?: any) => this.makeProtectedRequest(
    async (authHeaders: any) => super.delete(params, subUrl, {
      ...authHeaders,
      ...headers,
    }),
  );

  getAuthHeader = () => {
    const accessToken = this.getAccessToken();
    return accessToken ? `Bearer ${accessToken}` : null;
  };
}

export default ProtectedService;
