import React from 'react';
import classnames from 'classnames';

import { AuditChapter } from 'interfaces';

import List, { ListProps } from 'components/List';
import AppendixForm from 'forms/Appendix';

export interface AppendixListProps
  extends Omit<ListProps<AuditChapter>, 'renderItem'> {}

const ChaptersList: React.FC<AppendixListProps> = ({ className, ...props }) => {
  const classNames = classnames('chapters-list', className);
  return (
    <List<AuditChapter>
      className={classNames}
      {...props}
      component={AppendixForm}
      addText="Add appendix"
      sortable
    />
  );
};

export default ChaptersList;
