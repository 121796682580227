import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Page from 'containers/page';
import { useUser } from 'providers/UserProvider';
import { getClassificatorValue, ReportTemplate, ReportTemplatePrefix } from 'interfaces';

import './index.css';
import EnumPanel from './enumPanel';
import { EnumTypes } from '../../services/EnumsService';
import UsersPanel from './usersPanel';
import TemplatesPanel from './templatesPanel';

const Admin = () => {
  const [key, setKey] = useState('networks');
  const profile = useUser();
  const isHashex = profile?.reportType === getClassificatorValue(ReportTemplate, ReportTemplatePrefix.HASHEX);

  return (
    <Page title="Admin">
      <Tabs activeKey={key} onSelect={(k) => setKey(k!)}>
        <Tab eventKey="networks" title="Networks">
          <EnumPanel enumType={EnumTypes.NETWORK} />
        </Tab>
        <Tab eventKey="statuses" title="Statuses">
          <EnumPanel enumType={EnumTypes.AUDIT_STATUS} />
        </Tab>
        <Tab eventKey="issues" title="Issues">
          <EnumPanel enumType={EnumTypes.ISSUE_STATUS} />
        </Tab>
        <Tab eventKey="languages" title="Languages">
          <EnumPanel enumType={EnumTypes.CODE_LANGUAGE} />
        </Tab>
        <Tab eventKey="types" title="Audit Types">
          <EnumPanel enumType={EnumTypes.AUDIT_TYPE} />
        </Tab>
        <Tab eventKey="contractTypes" title="Contract Types">
          <EnumPanel enumType={EnumTypes.CONTRACT_TYPE} />
        </Tab>
        <Tab eventKey="contractTags" title="Contract Tags">
          <EnumPanel enumType={EnumTypes.CONTRACT_TAGS} />
        </Tab>
        <Tab eventKey="users" title="Users">
          <UsersPanel />
        </Tab>
        <Tab eventKey="templates" title="Templates">
          <TemplatesPanel isHashex={isHashex} />
        </Tab>
      </Tabs>
    </Page>
  );
};

export default Admin;
