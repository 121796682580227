import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import './index.css';

const attrId = 'data-popups';

const PopupComponent: React.FC<{
  showPopup: boolean;
  half?: boolean;
  full?: boolean;
  nested?: boolean;
  className?: string;
}> = ({
  showPopup, children, half = false, full = false, nested = false, className
}) => {
  const [holder] = useState<string>(`${Date.now()}`);

  useEffect(() => {
    const attrValue = document.body.getAttribute(attrId) || '';
    if (!attrValue) document.body.setAttribute(attrId, '');

    if (showPopup) {
      document.body.style.overflow = 'hidden';
      document.body.setAttribute(attrId, `${attrValue} ${holder}`);
    } else {
      if (!attrValue.includes(holder)) return;
      const newValue = attrValue.replace(holder, '').trim();
      document.body.setAttribute(attrId, newValue);
      if (!newValue) {
        document.body.style.overflow = 'auto';
      }
    }
  }, [showPopup]);

  useEffect(
    () => () => {
      const newValue = document.body
        .getAttribute(attrId)!
        .replace(holder, '')
        .trim();
      document.body.setAttribute(attrId, newValue);
      if (!newValue) {
        document.body.style.overflow = 'auto';
      }
    },
    [],
  );

  return (
    <Popup
      className={`c-popup ${half ? 'c-popup-half' : ''} ${
        full ? 'c-popup-full' : ''
      } ${className}`}
      closeOnDocumentClick={false}
      open={showPopup}
      position="center center"
      nested={nested}
    >
      {children}
    </Popup>
  );
};

export default PopupComponent;
