import React, { useState, useEffect } from 'react';
import {
  TemplateLabel,
  Templates,
  TemplateTypes
} from 'services/TemplatesService';
import TemplatesForm from 'forms/Templates';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'helpers';
import { TemplatesFormInterface } from 'interfaces';
import Loader from 'components/Loader';
import { useBackend } from '../../providers/BackendProvider';

const parseTemplate = (template: any) => (
  template ? JSON.parse(template) : null
);

const prepareTemplate = (template: any) => JSON.stringify(template);

const TemplatesPanel = ({ isHashex }: { isHashex: boolean}) => {
  const { templatesService } = useBackend();
  const [loading, setLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState<TemplatesFormInterface>();

  const fetchTemplate = async (templateName: string) => {
    setLoading(true);
    try {
      const newTemplate = await templatesService.get(null, `${TemplateTypes[templateName as keyof typeof TemplateTypes]}`);
      setFormState({
        ...formState,
        [templateName]: parseTemplate(newTemplate?.template) || '',
      });
    } catch (e) {
      toast.error(`Fetch template error. ${getErrorMessage(e)}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchTemplates = async () => {
    setLoading(true);
    try {
      let newState = {};
      await Promise.all(
        Object.entries(TemplateTypes).map(async ([templateName, templateType]) => {
          const newTemplate = await templatesService.get(null, `${templateType}`);
          newState = { ...newState, [templateName]: parseTemplate(newTemplate?.template) || '' };
          return newTemplate;
        })
      );
      setFormState(newState);
    } catch (e) {
      toast.error(`Fetch templates error. ${getErrorMessage(e)}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isHashex) {
      fetchTemplates();
    } else fetchTemplate(Templates.introduction);
  }, []);

  const onUpdate = async (value: TemplatesFormInterface) => {
    try {
      const keys = Object.keys(value);
      const templateName = keys[0] as keyof typeof value;
      await templatesService.editOne(
        prepareTemplate(value[templateName]),
        `${TemplateTypes[templateName]}`
      );
      toast.success('Template updated');
      fetchTemplate(templateName);
    } catch (e) {
      toast.error(`Update template error. ${getErrorMessage(e)}`);
    }
  };

  return (
    <div className="form-wrapper">
      {loading || !formState ? (
        <Loader />
      ) : (
        <>
          <TemplatesForm value={formState.introduction} name={Templates.introduction} label={TemplateLabel.introduction} onSubmit={onUpdate} />
          {isHashex && (
            <>
              <TemplatesForm value={formState.publishInWork} name={Templates.publishInWork} label={TemplateLabel.publishInWork} onSubmit={onUpdate} />
              <TemplatesForm value={formState.publishFinal} name={Templates.publishFinal} label={TemplateLabel.publishFinal} onSubmit={onUpdate} />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TemplatesPanel;
