import React from 'react';

import Page from 'containers/page';
import AutoAuditsContainer from '../../../components/Table/AutoAuditsTable/index';

const FormAudit = () => (
  <Page title="Generate Auto Audit">
    <AutoAuditsContainer />
  </Page>
);

export default FormAudit;
