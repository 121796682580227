import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Col, CloseButton } from 'react-bootstrap';
import { FaRegTimesCircle } from 'react-icons/fa';
import Button from '../../Button';
import { AuditInTable, User } from '../../../interfaces';
import { useUser } from '../../../providers/UserProvider';
import { getErrorMessage } from '../../../helpers';
import Popup from '../../Popup/Popup';
import './index.css';
import { useBackend } from '../../../providers/BackendProvider';

const ActiveAuditor: React.FC<{
  audit: AuditInTable;
  fetchAudits: any;
  auditors: Array<User>;
}> = ({ audit, fetchAudits, auditors }) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { auditService } = useBackend();
  const profile = useUser();
  const name = auditors.find((item) => item.id === audit.currentUser)?.name;

  const closePopup = () => {
    if (isSaving) return;
    setShowPopup(false);
  };

  const unassign = async () => {
    if (isSaving) return;
    setIsSaving(true);
    try {
      await auditService.unassign(audit.id as number);
      setShowPopup(false);
      toast.success('Active user is unassigned');
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsSaving(false);
    }
    await fetchAudits();
  };

  return (
    <div className="active-auditor">
      {name}
      {profile?.isAdmin || audit.currentUser === profile?.id ? (
        <>
          <Button
            title="Unassign"
            circle
            variant="danger"
            onClick={() => setShowPopup(true)}
            loading={isSaving}
          >
            <FaRegTimesCircle />
          </Button>
          <Popup showPopup={showPopup}>
            <Row className="mb-4">
              <Col className="d-flex justify-content-end">
                <CloseButton onClick={closePopup} />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <div className="create-text-wrapper">
                  <p className="h5">Do you want to unassign this user?</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button className="btn-danger m-0" onClick={unassign}>
                  Unassign
                </Button>
              </Col>
            </Row>
          </Popup>
        </>
      ) : null}
    </div>
  );
};

export default ActiveAuditor;
