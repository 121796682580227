export interface SelectOption {
  value: any;
  label: string;
}

export const getClassificatorValue = (ENUM: any, prefix: string): number => ENUM[`${prefix}_${process.env.REACT_APP_ENV}`];

export enum ContractLanguagePrefix {
  SOLIDITY = 'SOLIDITY',
  RUST = 'RUST',
  C = 'C',
}

export enum ReportTypePrefix {
  SIMPLE= 'SIMPLE',
  NORMAL = 'NORMAL',
  PRO = 'PRO',
  COMPLEX = 'COMPLEX'
}

export enum ReportTemplatePrefix {
  'HASHEX' = 'HASHEX',
  '0XGUARD' = '0XGUARD',
  'RUGDOG' = 'RUGDOG',
  'RAPID_LABS' = 'RAPID_LABS',
  'RUGFREE' = 'RUGFREE',
}

export enum NetworkPrefix {
  BSC = 'BSC',
  SOLANA = 'SOLANA',
}

export enum AuditStatusPrefix {
  IN_WORK = 'IN_WORK',
  FINAL = 'FINAL_REPORT',
}

export enum IssueSeverityPrefix {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  INFO = 'INFO'
}

export enum CentralizationLevelPrefix {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum ProjectCentralRiskPrefix {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum ContractLanguage {
  'SOLIDITY_STAGE' = 0,
  'SOLIDITY_PROD' = 0,
  'RUST_STAGE' = 1,
  'RUST_PROD' = 1,
  'C_STAGE' = 2,
  'C_PROD' = 2,
}

export enum ReportType {
  'SIMPLE_STAGE' = 0,
  'SIMPLE_PROD' = 0,
  'NORMAL_STAGE' = 1,
  'NORMAL_PROD' = 1,
  'PRO_STAGE' = 2,
  'PRO_PROD' = 2,
  'COMPLEX_STAGE' = 3,
  'COMPLEX_PROD' = 3,
}

export enum ReportTemplate {
  'HASHEX_STAGE' = 0,
  'HASHEX_PROD' = 0,
  '0XGUARD_STAGE' = 1,
  '0XGUARD_PROD' = 1,
  'RUGDOG_STAGE' = 2,
  'RUGDOG_PROD' = 2,
  'RAPID_LABS_STAGE' = 3,
  'RAPID_LABS_PROD' = 3,
  'RUGFREE_STAGE' = 4,
  'RUGFREE_PROD' = 4,
}

export enum Network {
  'BSC_STAGE' = 0,
  'BSC_PROD' = 0,
  'SOLANA_STAGE' = 8,
  'SOLANA_PROD' = 12,
}

export enum AuditStatus {
  'IN_WORK_STAGE' = 0,
  'IN_WORK_PROD' = 0,
  'FINAL_REPORT_STAGE' = 3,
  'FINAL_REPORT_PROD' = 3,
  'CANCELLED_STAGE' = 5,
  'CANCELLED_PROD' = 4,
}

export enum IssueSeverity {
  'CRITICAL_STAGE' = 4,
  'CRITICAL_PROD' = 4,
  'HIGH_STAGE' = 3,
  'HIGH_PROD' = 3,
  'MEDIUM_STAGE' = 2,
  'MEDIUM_PROD' = 2,
  'LOW_STAGE' = 1,
  'LOW_PROD' = 1,
  'INFO_STAGE' = 0,
  'INFO_PROD' = 0,
}

export enum CentralizationLevel {
  'HIGH_STAGE' = 2,
  'HIGH_PROD' = 2,
  'MEDIUM_STAGE' = 1,
  'MEDIUM_PROD' = 1,
  'LOW_STAGE' = 0,
  'LOW_PROD' = 0,
}

export enum ProjectCentralRisk {
  'HIGH_STAGE' = 2,
  'HIGH_PROD' = 2,
  'MEDIUM_STAGE' = 1,
  'MEDIUM_PROD' = 1,
  'LOW_STAGE' = 0,
  'LOW_PROD' = 0,
}

export enum ErrorName {
  TokenExpiredError = 'TokenExpiredError',
}

export interface Issue extends Sortable {
  id?: number;
  severity: number;
  name: string;
  description: any;
  recommendation: any;
  resolution: number;
  comment: Comment[];
  standard?: boolean;
}

export interface CentralRisk extends Sortable {
    id?: number;
    title: string;
    description: any;
    comments: Comment[];
    recommendations: Recommendation[];
}

export interface Chapter extends Sortable {
  id?: number;
  title: string;
  body: any;
}

export interface AuditChapter extends Sortable {
  id?: number;
  title: string;
  body: any;
  appendix?: number;
}

export interface Comment {
  title: string;
  body: any;
}

export interface Recommendation {
  title: string;
  body: any;
}

export interface Contract extends Sortable {
  id?: number;
  name: string;
  address: string;
  type?: number;
  description: string;
  issues: Issue[];
  centralRisks: CentralRisk[];
  chapters: Chapter[];
}

export interface Sortable {
  order: number;
}

export interface Audit {
  id?: number;
  projectName: string;
  projectLanguage: number;
  centralizationLevel: number | null;
  projectCentralRisk: number | null;
  projectUrl: string;
  description: any;
  generalCentralRisks: any;
  disclaimer: any;
  contracts: Contract[];
  currentUser?: User;
  editors?: Editor[];
  status?: number;
  type: number;
  dateStart: Date;
  dateEnd: Date;
  chapters: AuditChapter[];
  appendixes: AuditChapter[];
  networks: ProjectNetwork[];
  userLastUpdate?: Date;
  company?: number;
  externalUrl?: string;
  projectTelegram?: string;
  projectTwitter?: string;
  projectGithub?: string;
  projectLinkedin?: string;
  projectMedium?: string;
  projectCoinMarketCap?: string;
  isPrivate: boolean;
  isTop: boolean;
  isDeployed: boolean;
  projectIcon: string | null;
  slug: string;
  coinCodexSlug?: string;
}

export interface AuditInTable {
  id: number;
  projectName: string;
  projectLanguage: number;
  projectUrl: string;
  contractsLength: number;
  issuesLength: number;
  currentUser: number;
  editors: string;
  status: number;
  dateStart: Date;
  networks: string;
  userLastUpdate: Date;
}

export interface Editor {
  id?: number;
  editor: User;
}

export interface ProjectNetwork {
  id?: number;
  network: number;
}

export interface User {
  id: number;
  name: string;
  blocked: boolean;
  roles?: string;
}

export interface Profile {
  id: number;
  name: string;
  roles: string;
  company: string;
  portfolio: string;
  reportType: number;
  isAdmin: boolean;
}

export interface IssueInTable {
  id: number;
  name: string;
  projectName: string;
  projectId: number;
  contractName: string;
  contractType: number;
  resolution: number;
  severity: number;
  status: number;
  standard: boolean;
  editors: string;
  currentUser: number | null
}

export interface AutoAuditsInTable {
  id: number;
  dateTimeStamp: string;
  tokenName: string;
  tokenSymbol: string;
  tokenAddress: string;
  explorerLink: string;
  networkName: string;
  totalSupply: string;
  externalUrl: string;
  published: boolean;
  price: string;
  tvl: string;
  tvlUsd: string;
  type: string;
}

export interface StandardIssueInTable {
  id: number;
  createdAt: Date;
  name: string;
  contractType: number;
  severity: number;
  tags: string;
  isDeleted: boolean;
}

export interface StandardIssue {
  id: number;
  createdAt: Date;
  name: string;
  description: string;
  contractType: number;
  severity: number;
  tags: Array<StandardIssueContractTag>;
}

export interface StandardIssueContractTag {
  id?: number;
  contractTag: number;
}

export interface TemplatesFormInterface {
  introduction?: any;
  publishInWork?: any;
  publishFinal?: any;
}

export interface Template {
  type: number;
  template: string;
}
