import React, { useState } from 'react';
import {
  ListGroup, Row, Col, CloseButton,
} from 'react-bootstrap';
import {
  FaTimes, FaRegPlusSquare, FaUndo, FaEdit,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
import Popup from '../../components/Popup/Popup';
import { EnumTypes } from '../../services/EnumsService';
import { getErrorMessage } from '../../helpers';
import Input from '../../components/Input';
import { useEnums } from '../../providers/EnumsProvider';
import { useBackend } from '../../providers/BackendProvider';

const EnumPanel: React.FC<{ enumType: EnumTypes }> = ({ enumType }) => {
  const { enumsService } = useBackend();
  const [showDisable, setShowDisable] = useState<boolean>(false);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [newItem, setNewItem] = useState<string>('');
  const [isResponseLoading, setIsResponseLoading] = useState<boolean>(false);

  const { filterAllEnums, fetch } = useEnums();
  const networkEnums = filterAllEnums(enumType);

  const disableItem = async () => {
    if (isResponseLoading) return;
    setIsResponseLoading(true);
    try {
      await enumsService.switchActive(
        enumType,
        selectedItem!.key,
        selectedItem!.active === 'F' ? 'Y' : 'F',
      );
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onDisableClose(true);
      fetch();
      toast.success(
        `Item ${selectedItem!.active === 'F' ? 'enabled' : 'disabled'}`,
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsResponseLoading(false);
    }
  };

  const createItem = async () => {
    if (isResponseLoading) return;
    if (!newItem) return;
    setIsResponseLoading(true);
    try {
      await enumsService.createOne(enumType, newItem);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onCreateClose(true);
      fetch();
      toast.success('Item saved');
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsResponseLoading(false);
    }
  };

  const editItem = async () => {
    if (isResponseLoading) return;
    if (!newItem) return;
    setIsResponseLoading(true);
    try {
      await enumsService.updateOne(enumType, selectedItem.key, newItem);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onEditClose(true);
      fetch();
      toast.success('Item updated');
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsResponseLoading(false);
    }
  };

  const onDisableConfirm = (network: any) => {
    setShowDisable(true);
    setSelectedItem(network);
  };

  const onEditConfirm = (network: any) => {
    setShowEdit(true);
    setNewItem(network.value);
    setSelectedItem(network);
  };

  const onDisableClose = (force = false) => {
    if (!force && isResponseLoading) return;
    setShowDisable(false);
    setSelectedItem(null);
  };

  const onEditClose = (force = false) => {
    if (!force && isResponseLoading) return;
    setShowEdit(false);
    setNewItem('');
    setSelectedItem(null);
  };

  const onCreateClose = (force = false) => {
    if (!force && isResponseLoading) return;
    setShowCreate(false);
    setNewItem('');
  };

  const onNewItemTyped = (event: any) => {
    setNewItem(event.target.value);
  };

  return (
    <>
      <ListGroup>
        {networkEnums.map((network: any) => (
          <ListGroup.Item key={network.key} className="list-group-item__enum">
            {network.value}
            <Button
              className="item-btn item-btn_disable"
              title={network.active === 'F' ? 'Enable' : 'Disable'}
              size="sm"
              variant={network.active === 'F' ? 'primary' : 'danger'}
              onClick={() => onDisableConfirm(network)}
            >
              {network.active === 'F' ? <FaUndo /> : <FaTimes />}
            </Button>
            <Button
              className="item-btn item-btn_edit"
              title="Edit"
              size="sm"
              variant="secondary"
              onClick={() => onEditConfirm(network)}
            >
              <FaEdit />
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>
      <Button
        className="d-flex align-items-center add-new-btn mr-2"
        variant="primary"
        onClick={() => setShowCreate(true)}
      >
        <FaRegPlusSquare className="mx-2" />
        {' '}
        Add new
      </Button>
      <Popup showPopup={showDisable}>
        <Row className="mb-4">
          <Col className="d-flex justify-content-end">
            <CloseButton onClick={() => onDisableClose()} />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <p className="h5 mb-2">
              {`Do you want to ${
                selectedItem?.active === 'F' ? 'Enable' : 'Disable'
              } this item?`}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              className={`m-0 btn-${
                selectedItem?.active === 'F' ? 'success' : 'danger'
              }`}
              onClick={disableItem}
              loading={isResponseLoading}
            >
              {selectedItem?.active === 'F' ? 'Enable' : 'Disable'}
            </Button>
          </Col>
        </Row>
      </Popup>
      <Popup showPopup={showCreate}>
        <Row className="mb-4">
          <Col className="d-flex justify-content-end">
            <CloseButton onClick={() => onCreateClose()} />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <div className="create-text-wrapper">
              <p className="mb-2">Create new item</p>
              <Input value={newItem} onChange={onNewItemTyped} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              className="btn-success m-0"
              onClick={createItem}
              disabled={!newItem}
              loading={isResponseLoading}
            >
              Create
            </Button>
          </Col>
        </Row>
      </Popup>
      <Popup showPopup={showEdit}>
        <Row className="mb-4">
          <Col className="d-flex justify-content-end">
            <CloseButton onClick={() => onEditClose()} />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <div className="create-text-wrapper">
              <p className="mb-2">Edit item</p>
              <Input value={newItem} onChange={onNewItemTyped} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              className="btn-success m-0"
              onClick={editItem}
              disabled={!newItem}
              loading={isResponseLoading}
            >
              Edit
            </Button>
          </Col>
        </Row>
      </Popup>
    </>
  );
};

export default EnumPanel;
