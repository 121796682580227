import React, { useMemo } from 'react';

import Select, { SelectProps } from './Select';
import { useUser } from '../../providers/UserProvider';
import { EnumTypes } from '../../services/EnumsService';
import { useEnums } from '../../providers/EnumsProvider';

const CentralizationLevelSelect: React.FC<SelectProps> = React.forwardRef((props, ref) => {
  const profile = useUser();
  const { filterEnums, enums } = useEnums();

  const options = useMemo(() => {
    const items = filterEnums(EnumTypes.CENTRALIZATION_LEVEL);
    return items.map((item: any) => ({
      value: item.key,
      label: item.value,
    }));
  }, [enums, profile]);

  return <Select {...props} options={options} ref={ref} />;
});
CentralizationLevelSelect.displayName = 'CentralizationLevel';

export default CentralizationLevelSelect;
