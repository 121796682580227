import React, { useState } from 'react';
import { useFormContext } from 'components/Form/context';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import Input from 'components/Input';
import { MdOutlineFileUpload } from 'react-icons/md';
import { BsQuestionCircle } from 'react-icons/bs';
import css from './IconInput.module.scss';

const getBase64 = (file: any): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(`${reader.result}`);

    // Convert the file to base64 text
    reader.readAsDataURL(file);
  });
};

const ERROR_TEXT = 'Image must be square and at least 128x128. (Max size 100kb)';

// eslint-disable-next-line react/display-name
const IconInput: React.FC<any> = React.forwardRef<HTMLInputElement, { name: string }>((props: any, ref) => {
  const { name } = props;
  const { register, setValue } = useFormContext();
  const [error, setError] = useState<boolean>(false);
  const [base64Value, setBase64Value] = useState(props.defaultValue);

  const handleFileInputChange = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await getBase64(file);
    const pureBase64 = base64.replace(/^data:.*;base64,/, '');

    const img = new Image();
    img.src = window.URL.createObjectURL(file);

    img.onload = () => {
      const { width, height } = img;

      const sizeInKb = Buffer.from(pureBase64).length / 1024;

      if (width !== height || width < 128 || sizeInKb > 100) {
        setError(true);
      } else {
        setError(false);
        setValue(name, pureBase64);
        setBase64Value(pureBase64);
      }
    };
  };

  const onDelete = async () => {
    setValue(name, null);
    setBase64Value(null);
  };

  return (
    <div className={css.iconInputWrapper}>
      {
        !base64Value
          ? (
            <>
              <input
                type="file"
                name="project-icon-file"
                id="project-icon-file"
                className={css.iconInput}
                onChange={handleFileInputChange}
              />
              { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
              <label htmlFor="project-icon-file" title="Upload icon">
                <div className={css.labelIcon}>
                  <MdOutlineFileUpload color="#fff" />
                </div>
                <p className={css.fileChoose}>Choose a file</p>
              </label>
              <div className={css.hintTooltip}>
                <OverlayTrigger
                  overlay={<Tooltip placement="bottom">{ERROR_TEXT}</Tooltip>}
                >
                  <div className="d-inline-block mb-3">
                    <BsQuestionCircle />
                  </div>
                </OverlayTrigger>
              </div>
            </>
          )
          : (
            <>
              <img src={`data:*;base64,${base64Value}`} alt="" className={css.projectIcon} />
              {' '}
              <Button
                title="Delete"
                type="button"
                size="sm"
                variant="danger"
                onClick={onDelete}
                className={css.iconDeleteBtn}
              >
                <FaTimes />
              </Button>
            </>
          )
      }
      <Input {...register(name)} ref={ref} style={{ display: 'none' }} />
      {
        error && <p className={css.error}>{ERROR_TEXT}</p>
      }
    </div>
  );
});

export default IconInput;
