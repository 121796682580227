import React, { useRef, useCallback, useState } from 'react';
import ReactSelect from 'react-select';
import ReactPaginate from 'react-paginate';
import { AutoAuditsInTable } from 'interfaces';
import Table from 'components/Table';

import './index.css';
import { toast } from 'react-toastify';
import { FaUpload } from 'react-icons/all';
import DatePicker from 'react-date-picker';
import Button from '../../Button';
import { getErrorMessage } from '../../../helpers';
import Input from '../../Input';
import { useBackend } from '../../../providers/BackendProvider';

interface AutoAuditsTableProps {
  loading: boolean;
  audits: AutoAuditsInTable[];
  fetchAudits: (
    nPage?: number,
    nLimit?: number,
    nSortBy?: string,
    nSortDirection?: string,
    nFilter?: any
) => Promise<void>;
  sortBy: string;
  sortDirection: string;
  changeSort: (sort: string, direction: string) => void;
  onFilterChange: (field: string, value: any, needTimeout?: boolean) => void;
  page: number;
  pageCount: number;
  handlePageClick: (e: { selected: number }) => void;
  limit: number;
  onLimitChange: (value: number) => void;
}

const limitOptions = [
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
];

const AutoAuditsTable: React.FC<AutoAuditsTableProps> = ({
  loading,
  fetchAudits,
  audits,
  sortBy,
  sortDirection,
  changeSort,
  onFilterChange,
  page,
  pageCount,
  handlePageClick,
  limit,
  onLimitChange,
}) => {
  const [publishing, setPublishing] = useState<{[id: string]: boolean}>({});
  const [datePickerValue, setDatePickerValue] = useState();
  const tableWrapperElement = useRef<any>();
  const { autoAuditService } = useBackend();

  const publish = async (tokenId: number) => {
    if (publishing[tokenId]) return;
    setPublishing({
      ...publishing,
      [tokenId]: true
    });
    try {
      await autoAuditService.publish(`${tokenId}`);
      toast.success('Report published!');
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setPublishing({
        ...publishing,
        [tokenId]: false
      });
    }
    await fetchAudits();
  };

  const publishedOptions = [
    { label: 'true', value: true },
    { label: 'false', value: false },
  ];

  const onDateFilterChanged = (e: any) => {
    setDatePickerValue(e);
    onFilterChange('dateTimeStamp', e ? e.toDateString() : null, false);
  };

  const columns = [
    {
      Header: 'ID',
      accessor: (row: AutoAuditsInTable) => row.id,
    },
    {
      Header: 'Date',
      exactWidth: 185,
      accessor: (row: AutoAuditsInTable) => row.dateTimeStamp,
      isSortable: true,
      sortName: 'dateTimeStamp',
      isFilterable: true,
      filter: (
        <DatePicker
          format="yyyy-MM-dd"
          value={datePickerValue}
          onChange={onDateFilterChanged}
          className="c-date-picker"
        />
      )
    },
    {
      Header: 'Name',
      accessor: (row: AutoAuditsInTable) => row.tokenName,
      isSortable: true,
      sortName: 'tokenName',
      isFilterable: true,
      filter: (
        <Input
          onChange={(e) => onFilterChange('tokenName', e.target.value, true)}
        />
      )
    },
    {
      Header: 'Symbol',
      accessor: (row: AutoAuditsInTable) => row.tokenSymbol,
      isSortable: true,
      sortName: 'tokenSymbol',
      isFilterable: true,
      filter: (
        <Input
          onChange={(e) => onFilterChange('tokenSymbol', e.target.value, true)}
        />
      )
    },
    {
      Header: 'Type',
      accessor: (row: AutoAuditsInTable) => row.type,
      isSortable: true,
      sortName: 'type'
    },
    {
      Header: 'Address',
      accessor: (row: AutoAuditsInTable) => `${row.tokenAddress.substr(0, 4)}...${row.tokenAddress.substr(-4)}`,
      isFilterable: true,
      filter: (
        <Input
          onChange={(e) => onFilterChange('tokenAddress', e.target.value, true)}
        />
      )
    },
    {
      Header: 'Network',
      accessor: (row: AutoAuditsInTable) => row.networkName,
    },
    {
      Header: 'Explorer',
      accessor: useCallback((row: AutoAuditsInTable) => (<a href={row.explorerLink} target="_blank" rel="noreferrer noopener">Open</a>), []),
    },
    {
      Header: 'Price',
      accessor: (row: AutoAuditsInTable) => row.price,
    },
    {
      Header: 'Tvl, $',
      accessor: (row: AutoAuditsInTable) => row.tvlUsd,
      isSortable: true,
      sortName: 'tvlUsd',
    },
    {
      Header: 'Published',
      accessor: useCallback(
        (row: AutoAuditsInTable) => (
          <div className="checkbox-center">
            <input type="checkbox" className="checkbox" checked={row.published} disabled />
            { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="checkbox-label" />
          </div>
        ),
        []
      ),
      isSortable: true,
      sortName: 'published',
      isFilterable: true,
      filter: (
        <ReactSelect
          placeholder=""
          options={publishedOptions}
          onChange={(e) => onFilterChange('published', e?.value)}
          menuPlacement="auto"
          menuPosition="fixed"
          isClearable
        />
      ),
    },
    {
      Header: 'Pdf',
      accessor: useCallback((row: AutoAuditsInTable) => (row.externalUrl ? <a href={row.externalUrl} target="_blank" rel="noreferrer noopener">Open</a> : null), []),
    },
    {
      Header: 'Tools',
      accessor: useCallback((row: AutoAuditsInTable) => (row.externalUrl ? null : (<Button className="btn-success m-0" size="sm" onClick={() => publish(row.id)} loading={publishing[row.id] || false} title="Publish"><FaUpload /></Button>)), [publishing]),
      exactWidth: 50,
      fixed: true,
      fixedRightFirst: true,
      fixedValue: 0,
    }
  ];

  const scrollListener = (e: any) => {
    if (e.target.scrollLeft === 0) {
      e.target.classList.add('scroll-max-left');
    } else if (
      e.target.scrollLeft
      === e.target.scrollWidth - e.target.clientWidth
    ) {
      e.target.classList.add('scroll-max-right');
    } else {
      e.target.classList.remove('scroll-max-left');
      e.target.classList.remove('scroll-max-right');
    }
  };

  return (
    <>
      <div
        ref={tableWrapperElement}
        className={`table-container ${
          tableWrapperElement?.current?.scrollWidth
          > tableWrapperElement?.current?.clientWidth
            ? ''
            : 'scroll-max-right'
        }`}
        onScroll={scrollListener}
      >
        <Table
          loading={loading}
          data={audits}
          columns={columns}
          sortBy={sortBy}
          sortDirection={sortDirection}
          changeSort={changeSort}
        />
      </div>

      <div className="pagination-row">
        {pageCount > 1 && (
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={3}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            forcePage={page}
            renderOnZeroPageCount={undefined}
          />
        )}

        <ReactSelect
          className="options-select"
          placeholder=""
          options={limitOptions}
          value={limitOptions.find((item) => item.value === limit)}
          onChange={(e) => onLimitChange(e?.value!)}
        />
      </div>
    </>
  );
};

export default AutoAuditsTable;
