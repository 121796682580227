import Loader from 'components/Loader';
import React from 'react';
import { HeaderGroup, useTable } from 'react-table';

import './Table.css';

type ExtendedHeaderGroup = HeaderGroup & {
  isSortable?: boolean;
  isFilterable?: boolean;
  sortName?: string;
  filter?: string;
  fixed?: boolean;
  fixedLeft?: boolean;
  fixedValue?: number;
  fixedLeftLast?: boolean;
  fixedRightFirst?: boolean;
  exactWidth?: number;
};

interface TableProps {
  columns: any[];
  data: any;
  sortBy: string;
  sortDirection: string;
  changeSort: (sort: string, direction: string) => void;
  loading?: boolean;
}

const Table: React.FC<TableProps> = ({
  columns, data, loading, sortBy, sortDirection, changeSort
}) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const onChangeSort = (sort: string) => {
    if (sort === sortBy) {
      changeSort(sort, sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      changeSort(sort, sortDirection);
    }
  };

  // Render the UI for your table
  return (
    <table {...getTableProps()} className="app-table">
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <React.Fragment key={index}>
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: ExtendedHeaderGroup) => (
                // eslint-disable-next-line react/jsx-key
                <th
                  {...column.getHeaderProps({
                    style: {
                      ...(column.minWidth ? { minWidth: column.minWidth } : {}),
                      ...(column.exactWidth
                        ? { width: column.exactWidth }
                        : {}),
                      ...(column.fixed
                        ? {
                          [column.fixedLeft ? 'left' : 'right']:
                              column.fixedValue,
                        }
                        : {}),
                    },
                  })}
                  className={`${column.fixed ? 'fixed-cell' : ''}${
                    column.fixedRightFirst ? ' fixed-first-right-cell' : ''
                  }${column.fixedLeftLast ? ' fixed-last-left-cell' : ''}`}
                >
                  {column.isSortable ? (
                    <div className="sort-btn-wrap">
                      <button
                        type="button"
                        className="sort-btn"
                        onClick={() => onChangeSort(column.sortName!)}
                      >
                        {column.render('Header')}
                        <span
                          className={`sort-icon ${
                            sortDirection === 'desc'
                              ? 'sort-icon__down'
                              : 'sort-icon__up'
                          } ${
                            column.sortName === sortBy
                              ? 'sort-icon__active'
                              : ''
                          }`}
                        />
                      </button>
                    </div>
                  ) : (
                    column.render('Header')
                  )}
                </th>
              ))}
            </tr>
            <tr>
              { /* eslint-disable-next-line @typescript-eslint/no-shadow */}
              {headerGroup.headers.map((column: ExtendedHeaderGroup, index) => (
                <td
                  key={index}
                  style={{
                    ...(column.fixed
                      ? {
                        [column.fixedLeft ? 'left' : 'right']:
                            column.fixedValue,
                      }
                      : {}),
                  }}
                  className={`${column.fixed ? 'fixed-cell fixed-cell_control' : ''}${
                    column.fixedRightFirst ? ' fixed-first-right-cell' : ''
                  }${column.fixedLeftLast ? ' fixed-last-left-cell' : ''}`}
                >
                  {column.isFilterable ? column.render('filter') : null}
                </td>
              ))}
            </tr>
          </React.Fragment>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {loading ? (
          <Loader />
        ) : (
          rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell) => (
                  // eslint-disable-next-line react/jsx-key
                  <td
                    {...cell.getCellProps()}
                    style={{
                      ...((cell.column as ExtendedHeaderGroup).fixed
                        ? {
                          [(cell.column as ExtendedHeaderGroup).fixedLeft
                            ? 'left'
                            : 'right']: (cell.column as ExtendedHeaderGroup)
                            .fixedValue,
                        }
                        : {}),
                    }}
                    className={`${
                      (cell.column as ExtendedHeaderGroup).fixed
                        ? 'fixed-cell'
                        : ''
                    }${
                      (cell.column as ExtendedHeaderGroup).fixedRightFirst
                        ? ' fixed-first-right-cell'
                        : ''
                    }${
                      (cell.column as ExtendedHeaderGroup).fixedLeftLast
                        ? ' fixed-last-left-cell'
                        : ''
                    }`}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
};

export default Table;
