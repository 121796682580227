import React from 'react';
import classnames from 'classnames';
import { Recommendation } from 'interfaces';
import List, { ListProps } from 'components/List';
import RecommendationForm from 'forms/Reccomendation';

export interface RecommendationsListProps
    extends Omit<ListProps<Recommendation>, 'renderItem'> {}

const RecommendationList: React.FC<RecommendationsListProps> = ({ className, ...props }) => {
  const classNames = classnames('chapters-list', className);
  return (
    <List<Recommendation>
      className={classNames}
      {...props}
      component={RecommendationForm}
      addText="Add Recommendation"
    />
  );
};

export default RecommendationList;
