import React, { useEffect, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Button from 'components/Button/index';
import { StandardIssue } from '../../../interfaces';
import { getErrorMessage } from '../../../helpers';
import Popup from '../../Popup/Popup';
import StandardIssueForm from '../../../forms/StandardIssue';
import { useBackend } from '../../../providers/BackendProvider';

const prepareIssue = (issue: any) => ({
  ...issue,
  description: JSON.stringify(issue.description),
});

const parseIssue = (issue: any) => ({
  ...issue,
  description: issue.description ? JSON.parse(issue.description) : null,
});

const EditStandardIssueButton: React.FC<{ id: number; update: () => void }> = ({
  id,
  update,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isResponseLoading, setIsResponseLoading] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const { standardIssuesService } = useBackend();
  const [issue, setIssue] = useState<StandardIssue | null>(null);
  const fetchIssue = async () => {
    try {
      const response = await standardIssuesService.get(null, `${id}`);
      setIssue(parseIssue(response));
      setShowPopup(true);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    if (isResponseLoading) return;
    setIsResponseLoading(true);
    try {
      await standardIssuesService.patch(prepareIssue(data), `${id}`);
      toast.success('Issue updated');
      update();
      setShowPopup(false);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsResponseLoading(false);
    }
  };

  const onEditClose = () => {
    if (isResponseLoading) return;
    setShowPopup(false);
  };

  useEffect(() => {
    if (loading) {
      fetchIssue();
    }
  }, [loading]);

  return (
    <>
      <Button
        size="sm"
        title="Edit"
        variant="dark"
        onClick={() => setLoading(true)}
        loading={loading}
      >
        <FaRegEdit />
      </Button>
      <Popup showPopup={showPopup} half>
        <StandardIssueForm
          editMode
          state={issue!}
          onSubmit={onSubmit}
          onCreateClose={onEditClose}
          isSubmitting={isResponseLoading}
        />
      </Popup>
    </>
  );
};

export default EditStandardIssueButton;
