import React from 'react';
import {
  Card, Col, Collapse, Row
} from 'react-bootstrap';
import CodeTabs from '../CodeTabs';

export interface ContractListProps {
    contracts: any[];
    toggleCollapse: (contract: any) => void;
    collapsedContracts: any;
}

const ContractList: React.FC<ContractListProps> = ({
  contracts, toggleCollapse, collapsedContracts,
}) => (
  <>
    {contracts.map((contract) => (
      <Card key={contract.entitySHA256} className="fc-card">
        <Card.Header onClick={() => toggleCollapse(contract)}>
          <Row>
            <Col xs={12} md={3}>
              <b>{contract.entityName}</b>
            </Col>
            <Col xs={12} md={3}>
              {contract.exact ? <em>exact match</em> : ''}
              {!contract.exact && !contract.bestMatch ? <em>No matches found</em> : ''}
              {contract.bestMatch && !contract.exact
                ? (
                  <em>
                    similarity:
                    {'  '}
                    {contract.bestMatch.tunedSimilarity.toFixed(2)}
                  </em>
                )
                : ''}
            </Col>
            <Col xs={12} md={3}>
              <em>Code Lines:</em>
              {'  '}
              {contract.codeLines}
            </Col>
            <Col xs={12} md={3}>
              <em>Blank Lines:</em>
              {'  '}
              {contract.blankLines}
            </Col>
          </Row>
        </Card.Header>
        <Collapse in={!collapsedContracts[contract.entitySHA256]}>
          <Card.Body>
            <Row>
              <Col xs={12} md={12}>
                {
                                  contract.bestMatch
                                    ? (
                                      <div className="fc-match-meta">
                                        <p className="fc-meta-item">
                                          <b />
                                        </p>
                                        <p className="fc-meta-item">
                                          <b>Matched contract info:</b>
                                        </p>
                                        <p className="fc-meta-item">
                                          <b>Name: </b>
                                          {contract.bestMatch.name}
                                        </p>
                                        <p className="fc-meta-item">
                                          <b>Repository: </b>
                                          {contract.bestMatch.repository}
                                        </p>
                                        <p className="fc-meta-item">
                                          <b>Branch: </b>
                                          {contract.bestMatch.branch}
                                        </p>
                                        <p className="fc-meta-item">
                                          <b>Commit: </b>
                                          {contract.bestMatch.commit}
                                        </p>
                                        <p className="fc-meta-item">
                                          <b>Solidity version: </b>
                                          {contract.bestMatch.solidityVersion}
                                        </p>
                                      </div>
                                    )
                                    : ''
                              }
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <CodeTabs contract={contract} />
              </Col>
            </Row>
          </Card.Body>
        </Collapse>
      </Card>
    ))}
  </>
);

export default ContractList;
