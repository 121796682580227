import React, { useMemo } from 'react';
import Form, { FormField } from 'components/Form';
import FormFieldControl from 'components/FormFieldControl';
import { Col, Row } from 'react-bootstrap';
import SubmitButton from 'components/Button/SubmitButton';
import TextEditor from '../../components/TextEditor';

const getFields = (name: string, label: string): { [x: string]: FormField } => ({
  [name]: {
    label,
    component: TextEditor,
  },
});

interface TemplatesFormProps {
  value: any;
  name: string;
  onSubmit: any;
  label: string;
  isSubmitting?: boolean;
}

const TemplatesForm: React.FC<TemplatesFormProps> = ({
  value,
  label,
  name,
  onSubmit,
  isSubmitting = false,
}) => {
  const fields = useMemo(() => getFields(name, label), []);
  return (
    <Form
      onSubmit={onSubmit}
      fields={fields}
      silent
      defaultValues={{ [name]: value }}
    >
      <Row className="mb-2">
        <Col xs={12} md={12}>
          <FormFieldControl
            name={name}
            defaultValue={value}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={12} md={12} className="d-flex justify-content-end">
          <SubmitButton loading={isSubmitting} className="btn-success m-0">
            {/* eslint-disable-next-line no-nested-ternary */}
            {value ? 'Edit' : 'Create'}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
};

export default TemplatesForm;
