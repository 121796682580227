import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import './index.css';
import SourceCode from '../../SourceCode';
import CodeDiff from '../../CodeDiff';

export const eventKeys = {
  codeComparison: {
    key: 'code-comparison',
    title: 'Code Comparison'
  },
  uploadedSourceCode: {
    key: 'uploaded-source-code',
    title: 'Uploaded Source Code'
  },
  dbSourceCode: {
    key: 'db-source-code',
    title: 'Source Code from Database'
  },
};

export interface CodeTabsProps {
    contract: any
}

const CodeTabs: React.FC<CodeTabsProps> = React.memo(({ contract }) => (
  contract.bestMatch ? (
    <Tabs defaultActiveKey={eventKeys.codeComparison.key} id="code-tabs" className="mb-3 custom-tab-container">
      <Tab eventKey={eventKeys.codeComparison.key} title={eventKeys.codeComparison.title}>
        <CodeDiff diff={contract.diff} />
      </Tab>
      <Tab eventKey={eventKeys.dbSourceCode.key} title={eventKeys.dbSourceCode.title}>
        <SourceCode sourceCode={contract.bestMatch.contents} language="solidity" />
      </Tab>
      <Tab eventKey={eventKeys.uploadedSourceCode.key} title={eventKeys.uploadedSourceCode.title}>
        <SourceCode sourceCode={contract.entityCode} language="solidity" />
      </Tab>
    </Tabs>
  ) : (
    <Tabs defaultActiveKey={eventKeys.uploadedSourceCode.key} id="code-tabs" className="mb-3 custom-tab-container">
      <Tab eventKey={eventKeys.uploadedSourceCode.key} title={eventKeys.uploadedSourceCode.title}>
        <SourceCode sourceCode={contract.entityCode} language="solidity" />
      </Tab>
    </Tabs>
  )

));

CodeTabs.displayName = 'CodeTabs';

export default CodeTabs;
