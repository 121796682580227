import React, { useMemo } from 'react';

import { useUser } from 'providers/UserProvider';
import {
  getClassificatorValue, ReportTemplate, ReportType, ReportTypePrefix, ReportTemplatePrefix
} from 'interfaces';
import Select, { SelectProps } from './Select';
import { EnumTypes } from '../../services/EnumsService';
import { useEnums } from '../../providers/EnumsProvider';

// eslint-disable-next-line react/display-name
const AuditTypeSelect: React.FC<SelectProps> = React.forwardRef((props, ref) => {
  const { filterEnums, enums } = useEnums();
  const profile = useUser();
  const isRugdogCompany = profile?.reportType === getClassificatorValue(ReportTemplate, ReportTemplatePrefix.RUGDOG);
  const simpleTypeKey = getClassificatorValue(ReportType, ReportTypePrefix.SIMPLE);
  const complexTypeKey = getClassificatorValue(ReportType, ReportTypePrefix.COMPLEX);

  const options = useMemo(() => {
    const items = filterEnums(EnumTypes.AUDIT_TYPE);

    return items
      .filter((item) => {
        if (isRugdogCompany) {
          return item.key === simpleTypeKey || item.key === complexTypeKey;
        }

        return item.key !== complexTypeKey;
      })
      .map((item: any) => ({
        value: item.key,
        label: item.value
      }));
  }, [enums, profile]);

  return <Select {...props} options={options} ref={ref} />;
});

export default AuditTypeSelect;
