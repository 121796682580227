import React, { useMemo } from 'react';

import {
  getClassificatorValue,
  ReportTemplate,
  ReportTemplatePrefix,
} from 'interfaces';
import Select, { SelectProps } from './Select';
import { useUser } from '../../providers/UserProvider';
import { EnumTypes } from '../../services/EnumsService';
import { useEnums } from '../../providers/EnumsProvider';

// eslint-disable-next-line react/display-name
const SeveritySelect: React.FC<SelectProps> = React.forwardRef((props, ref) => {
  const profile = useUser();
  const { filterEnums, enums } = useEnums();

  const options = useMemo(() => {
    let items = filterEnums(EnumTypes.ISSUE_SEVERITY);
    if (
      profile?.reportType
      !== getClassificatorValue(ReportTemplate, ReportTemplatePrefix.HASHEX)
    ) {
      // eslint-disable-next-line max-len
      items = items.filter((item: any) => [0, 4].indexOf(item.key) === -1); // exclude critical/info item for 0xGuard and Rugdog
    }
    return items.map((item: any) => ({
      value: item.key,
      label: item.value,
    }));
  }, [enums, profile]);

  return <Select {...props} options={options} ref={ref} />;
});

export default SeveritySelect;
