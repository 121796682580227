import React, { useMemo, useState } from 'react';
import { Row, Col, CloseButton } from 'react-bootstrap';
import { FaRegCopy, FaRocket } from 'react-icons/fa';
import Button from 'components/Button/index';
import { toast } from 'react-toastify';
import Popup from '../../Popup/Popup';
import PublishDataForm from '../../../forms/PublishData';
import { getErrorMessage } from '../../../helpers';
import PublishTemplate, { copyToClip } from './PublishTemplate';
import { useBackend } from '../../../providers/BackendProvider';
import { useUser } from '../../../providers/UserProvider';
import { Audit } from '../../../interfaces';

interface PublishReportButtonProps {
  id: number;
  short?: boolean;
  url?: string;
  name: string;
  slug: string;
  isHashex: boolean;
  auditStatus: number;
  size?: 'sm' | 'lg';
  onChange?: () => void;
  audit: Audit|null;
}

const PublishReportButton: React.FC<PublishReportButtonProps> = ({
  id,
  short,
  url,
  size,
  name,
  slug,
  isHashex,
  auditStatus,
  onChange,
  audit
}) => {
  const { auditService } = useBackend();
  const profile = useUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(false);
  const confirm = () => {
    setShowConfirmationPopup(true);
  };

  const closeConfirm = () => {
    setShowConfirmationPopup(false);
  };

  const publish = () => {
    setShowPopup(true);
    closeConfirm();
  };

  const onSubmit = async (data: any) => {
    if (loading) return;
    setLoading(true);
    try {
      await auditService.publishAudit(
        id,
        data.path,
        data.name
      );

      toast.success('Report published!');
      setShowPopup(false);
      if (onChange) {
        onChange();
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    if (loading) return;
    setShowPopup(false);
  };

  const onCopyRepoClick = () => {
    copyToClip(url);
  };

  const portfolioLink = useMemo(() => `${profile?.portfolio?.replace('{slug}', slug)}`, [profile?.portfolio, slug]);

  const onCopyPortfolioClick = () => {
    copyToClip(portfolioLink);
  };

  return (
    <>
      <Button
        size={size}
        /* eslint-disable-next-line no-nested-ternary */
        title={short ? (url ? 'Republish' : 'Publish') : undefined}
        variant="success"
        onClick={url ? confirm : publish}
        loading={loading}
      >
        <FaRocket />
        {' '}
        {/* eslint-disable-next-line no-nested-ternary */}
        {short ? null : (url ? 'Republish' : 'Publish')}
      </Button>
      <Popup showPopup={showPopup} half>
        <PublishDataForm
          onSubmit={onSubmit}
          onClose={onClose}
          state={{
            path: name,
            name,
          }}
          isSubmitting={loading}
          btnName={url ? 'Republish' : 'Publish'}
        />
        <PublishTemplate auditStatus={auditStatus} isHashex={isHashex} audit={audit} />
      </Popup>

      <Popup showPopup={showConfirmationPopup} half>
        <Row className="mb-4">
          <Col className="d-flex justify-content-end">
            <CloseButton onClick={closeConfirm} />
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <p className="h5">Are you sure you want to republish this report?</p>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col className="d-flex justify-content-end">
            <Button className="btn-success m-0" onClick={publish}>
              Republish
            </Button>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col xs={11} md={11} className="d-flex justify-content-end">
            <Button variant="link" href={url} target="_blank" rel="noopener noreferrer">
              Published report
            </Button>
          </Col>
          <Col xs={1} md={1} className="d-flex justify-content-end">
            <Button
              size="sm"
              title="Copy to clipboard"
              variant="success"
              onClick={onCopyRepoClick}
            >
              <FaRegCopy />
            </Button>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col xs={11} md={11} className="d-flex justify-content-end">
            <Button variant="link" href={portfolioLink} target="_blank" rel="noopener noreferrer">
              Portfolio link
            </Button>
          </Col>
          <Col xs={1} md={1} className="d-flex justify-content-end">
            <Button
              size="sm"
              title="Copy to clipboard"
              variant="success"
              onClick={onCopyPortfolioClick}
            >
              <FaRegCopy />
            </Button>
          </Col>
        </Row>

        <PublishTemplate auditStatus={auditStatus} isHashex={isHashex} audit={audit} />
      </Popup>
    </>
  );
};

export default PublishReportButton;
