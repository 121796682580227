import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  Audit,
  getClassificatorValue,
  ReportTemplate,
  ReportTemplatePrefix,
  Sortable,
} from 'interfaces';

import Page from 'containers/page';

import AuditForm from 'forms/Audit';

import Loader from 'components/Loader';
import DownloadPDFReportButton from 'components/Button/DownloadReportPdfButton';
import AssignAuditorButton from '../../../components/Button/AssignAuditorButton';
import { useUser } from '../../../providers/UserProvider';
import PublishReportButton from '../../../components/Button/PublishReportButton';
import { addOffsetDate } from '../../../helpers';
import { useBackend } from '../../../providers/BackendProvider';
import DownloadReportTextButton from '../../../components/Button/DownloadReportTextButton';

const sortByOrder = (a: Sortable, b: Sortable) => a.order - b.order;

const parseAudit = (audit: Audit) => ({
  ...audit,
  dateStart: addOffsetDate(audit.dateStart) as Date,
  dateEnd: addOffsetDate(audit.dateEnd) as Date,
  userLastUpdate: addOffsetDate(audit.userLastUpdate),
  description: JSON.parse(audit.description || '{}'),
  generalCentralRisks: JSON.parse(audit.generalCentralRisks || '{}'),
  disclaimer: JSON.parse(audit.disclaimer || '{}'),
  contracts: (audit?.contracts || [])
    .sort(sortByOrder)
    .map((contract) => ({
      ...contract,
      description: JSON.parse(contract.description || '{}'),
      centralRisks: (contract?.centralRisks || [])
        .sort(sortByOrder)
        .map((centralRisk) => ({
          ...centralRisk,
          description: JSON.parse(centralRisk.description),
          recommendations: JSON.parse((centralRisk?.recommendations as any) || '[]'),
          comments: JSON.parse((centralRisk?.comments as any) || '[]'),
        })),
      issues: (contract?.issues || [])
        .sort((a, b) => b.severity - a.severity)
        .map((issue) => ({
          ...issue,
          description: JSON.parse(issue.description),
          recommendation: JSON.parse(issue.recommendation),
          comment: JSON.parse((issue?.comment as any) || '[]'),
        })),
      chapters: (contract?.chapters || [])
        .sort(sortByOrder)
        .map((chapter) => ({
          ...chapter,
          body: JSON.parse(chapter.body),
        })),
    })),
  chapters: (audit?.chapters?.filter((chapter) => !chapter.appendix) || [])
    .sort(sortByOrder)
    .map((chapter) => ({
      ...chapter,
      body: JSON.parse(chapter.body),
    })),
  appendixes: (audit?.chapters?.filter((chapter) => chapter.appendix) || [])
    .sort(sortByOrder)
    .map((chapter) => ({
      ...chapter,
      body: JSON.parse(chapter.body),
    })),
});

const EditAudit = () => {
  const { auditService } = useBackend();
  const { id } = useParams<{ id: string }>();
  const profile = useUser();
  const isHashex = profile?.reportType === getClassificatorValue(ReportTemplate, ReportTemplatePrefix.HASHEX);
  const isAdmin = profile?.isAdmin;
  const [audit, setAudit] = useState<Audit | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const fetchAudit = async (auditId: string) => {
    setLoading(true);
    const resp = await auditService.get(null, auditId);
    setAudit(parseAudit(resp));
    setLoading(false);
  };

  useEffect(() => {
    fetchAudit(id);
  }, [id]);
  const intId = parseInt(id);

  const onChange = () => fetchAudit(id);

  return (
    <Page
      title="Edit Audit"
      toolbar={(
        <>
          {/* id && <PreviewReportButton id={intId} /> */}
          {id && (
            <DownloadReportTextButton
              id={intId}
              status={audit?.status}
              name={audit?.projectName!}
            />
          )}
          {id && (
            <DownloadPDFReportButton
              id={intId}
              status={audit?.status}
              name={audit?.projectName!}
              hotkeyAllow
            />
          )}
          {id && audit && isAdmin && (
            <PublishReportButton
              auditStatus={audit.status!}
              id={intId}
              url={audit.externalUrl}
              name={audit.projectName}
              slug={audit.slug}
              onChange={onChange}
              isHashex={isHashex}
              audit={audit}
            />
          )}
          {id && audit && <AssignAuditorButton audit={audit} />}
        </>
      )}
    >
      {loading || !audit ? (
        <Loader />
      ) : (
        <AuditForm audit={audit} onChange={onChange} />
      )}
    </Page>
  );
};

export default EditAudit;
