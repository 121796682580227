import React, { useCallback, useState, useEffect } from 'react';
import { FaDownload } from 'react-icons/fa';
import { toast } from 'react-toastify';

import Button from 'components/Button/index';
import { getErrorMessage } from 'helpers';
import {
  getClassificatorValue,
  AuditStatus,
  AuditStatusPrefix,
  ReportTemplate,
  ReportTemplatePrefix,
} from 'interfaces';
import { useUser } from '../../../providers/UserProvider';
import { useBackend } from '../../../providers/BackendProvider';

interface DownloadPDFReportButtonProps {
  id: number;
  short?: boolean;
  name: string;
  status?: AuditStatus;
  size?: 'sm' | 'lg';
  hotkeyAllow?: boolean;
}

const prepareName = (name: string) => name.replaceAll(/\s/g, '-');

const getFilename = (
  name: string,
  reportTemplate?: number,
  status?: AuditStatus,
) => {
  const preparedName = prepareName(name);
  const isDraft = status === getClassificatorValue(AuditStatus, AuditStatusPrefix.IN_WORK);
  const isHashex = reportTemplate
    === getClassificatorValue(ReportTemplate, ReportTemplatePrefix.HASHEX);
  return `${preparedName}_${
    // eslint-disable-next-line no-nested-ternary
    isHashex && isDraft ? 'preliminary-' : !isHashex ? 'final-' : ''
  }audit-report_${Date.now()}.pdf`;
};

const DownloadPDFReportButton: React.FC<DownloadPDFReportButtonProps> = ({
  id,
  short,
  name,
  status,
  size,
  hotkeyAllow
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { auditService } = useBackend();
  const profile = useUser();
  const downloadPDF = useCallback(async () => {
    setLoading(true);
    try {
      await auditService.downloadPdf(
        id,
        getFilename(name, profile?.reportType, status),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [id, name, status]);

  const onKeydown = async (e: KeyboardEvent) => {
    if ((e.code === 'KeyD' || e.code === 'Digit4') && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      await downloadPDF();
    }
  };

  useEffect(() => {
    if (hotkeyAllow) {
      document.addEventListener('keydown', onKeydown);
    }

    return () => {
      if (hotkeyAllow) {
        document.removeEventListener('keydown', onKeydown);
      }
    };
  }, [name]);

  return (
    <Button
      size={size}
      title={short ? 'Download PDF' : undefined}
      variant="success"
      onClick={downloadPDF}
      loading={loading}
    >
      <FaDownload />
      {' '}
      {short ? null : 'Download PDF'}
    </Button>
  );
};

export default DownloadPDFReportButton;
