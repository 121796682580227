import React from 'react';
import Page from 'containers/page';
import LineCounterPage from '../../components/LineCounterPage';

const LineCounter = () => (
  <Page title="Line Counter">
    <LineCounterPage />
  </Page>
);

export default LineCounter;
