import React, { useEffect, useRef } from 'react';
import DatePicker from 'react-date-picker';
import { Controller } from 'react-hook-form';
import cn from 'classnames';

import './DateInput.scss';

import { useFormContext } from 'components/Form/context';

interface DatePickerProps {
  onChange: (e: any) => void;
  name: string;
  value: any;
  defaultValue?: any;
  ref: any;
  control?: any;
}

// eslint-disable-next-line react/display-name
const DateInput: React.FC<DatePickerProps> = React.forwardRef(({ defaultValue, name, control }) => {
  const wrapperRef = useRef(null);
  const {
    formState: { errors }
  } = useFormContext();
  const firstError = React.useMemo(() => Object.keys(errors)[0], [errors]);

  useEffect(() => {
    if (name === firstError) {
      // @ts-ignore
      wrapperRef.current.scrollIntoView();
    }
  }, [errors]);

  return (
    <div ref={wrapperRef}>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name as `${string}`}
        render={(props: any) => {
          const { value, onChange } = props.field;
          return (
            <DatePicker
              format="dd/MM/y"
              inputRef={props.ref}
              value={value}
              onChange={onChange}
              className={cn({ error: errors[name] })}
            />
          );
        }}
      />
    </div>
  );
});

export default DateInput;
