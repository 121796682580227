import { createReducer, nanoid } from '@reduxjs/toolkit';
import {
  addPopup,
  ApplicationModal,
  PopupContent,
  removePopup,
  setOpenModal,
} from './actions';

type PopupList = Array<{
  key: string;
  show: boolean;
  content: PopupContent;
  removeAfterMs: number | null;
}>;

export interface ApplicationState {
  readonly blockNumber: { readonly [chainId: number]: number };
  readonly popupList: PopupList;
  readonly openModal: ApplicationModal | null;
  kashiApprovalPending: string;
}

const initialState: ApplicationState = {
  blockNumber: {},
  popupList: [],
  openModal: null,
  kashiApprovalPending: '',
};

export default createReducer(initialState, (builder) => builder
  .addCase(setOpenModal, (state, action) => {
    // eslint-disable-next-line no-param-reassign
    state.openModal = action.payload;
  })
  .addCase(
    addPopup,
    (state, { payload: { content, key, removeAfterMs = 15000 } }) => {
      // eslint-disable-next-line no-param-reassign
      state.popupList = (
        key
          ? state.popupList.filter((popup) => popup.key !== key)
          : state.popupList
      ).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs,
        },
      ]);
    },
  )
  .addCase(removePopup, (state, { payload: { key } }) => {
    state.popupList.forEach((p) => {
      if (p.key === key) {
        // eslint-disable-next-line no-param-reassign
        p.show = false;
      }
    });
  }));
