import React from 'react';
import Page from 'containers/page';
import ChangePasswordForm from '../../../forms/ChangePassword';

const ChangePwd = () => (
  <Page title="Change password">
    <ChangePasswordForm />
  </Page>
);

export default ChangePwd;
