import React, { useEffect, useState } from 'react';
import {
  Row, Col, Collapse, Card,
} from 'react-bootstrap';

import { AuditChapter } from 'interfaces';
import { useFormContext } from 'components/Form/context';

import { FormFields } from 'components/Form';
import FormFieldControl from 'components/FormFieldControl';
import TextEditor from 'components/TextEditor';

export const fields: FormFields = {
  title: {
    label: 'Chapter title',
  },
  body: {
    label: 'Chapter content',
    component: TextEditor,
  },
};

interface AuditChapterFormProps {
  name: string;
  value: AuditChapter;
}

const AuditChapterForm: React.FC<AuditChapterFormProps> = ({ name, value }) => {
  const [collapsed, setCollapsed] = useState<boolean>(!value.title);
  const { collapseAllCb } = useFormContext();
  const toggleCollapse = () => setCollapsed(!collapsed);

  useEffect(() => {
    collapseAllCb(() => {
      setCollapsed(false);
    });
  }, [collapseAllCb]);

  return (
    <Card>
      <Card.Header onClick={toggleCollapse}>
        {value.title || 'Enter chapter title'}
      </Card.Header>
      <Collapse in={collapsed}>
        <Card.Body>
          <Row>
            <Col xs={12} md={12}>
              <FormFieldControl
                name={`${name}.title`}
                defaultValue={value.title}
              />
            </Col>
            <Col xs={12} md={12}>
              <FormFieldControl
                name={`${name}.body`}
                defaultValue={value.body}
              />
            </Col>
          </Row>
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default AuditChapterForm;
