import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  getClassificatorValue, ReportTemplate, ReportTemplatePrefix
} from 'interfaces';
import FormFieldControl from 'components/FormFieldControl';
import CollapsibleItem from 'components/CollapsibleItem';
import { CompanyMap, FieldProps } from '../interfaces';

const DisclaimerField: React.FC<FieldProps> = ({ audit }) => {
  const { disclaimer } = audit;

  return (
    <Row>
      <Col>
        <CollapsibleItem title="Disclaimer">
          <FormFieldControl name="disclaimer" defaultValue={disclaimer} />
        </CollapsibleItem>
      </Col>
    </Row>
  );
};

const disclaimer: CompanyMap = {
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix.HASHEX)]: DisclaimerField,
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix['0XGUARD'])]: () => null,
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix.RUGDOG)]: () => null
};

export default disclaimer;
