import React, { useState } from 'react';
import { FaRegCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Row, Col, CloseButton } from 'react-bootstrap';
import Button from 'components/Button/index';
import { AuditInTable } from '../../../interfaces';
import { getErrorMessage } from '../../../helpers';
import Popup from '../../Popup/Popup';
import Input from '../../Input';
import { useBackend } from '../../../providers/BackendProvider';

const DuplicateAuditButton: React.FC<{
  audit: AuditInTable;
  updateAudits?: () => void;
}> = ({ audit, updateAudits = () => {} }) => {
  const { auditService } = useBackend();
  const [isResponseLoading, setIsResponseLoading] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [name, setName] = useState<string>(`duplicate of ${audit.projectName}`);

  const duplicate = async () => {
    setIsResponseLoading(true);
    try {
      await auditService.duplicateAudit(audit.id, name);
      setShowPopup(false);
      await updateAudits();
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsResponseLoading(false);
    }
  };

  const onPopupClose = () => {
    if (isResponseLoading) return;
    setShowPopup(false);
  };

  const onNewItemTyped = (event: any) => {
    setName(event.target.value);
  };

  return (
    <>
      <Button
        size="sm"
        title="Duplicate"
        variant="warning"
        onClick={() => setShowPopup(true)}
      >
        <FaRegCopy />
      </Button>
      <Popup showPopup={showPopup}>
        <Row className="mb-4">
          <Col className="d-flex justify-content-end">
            <CloseButton onClick={onPopupClose} />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className="create-text-wrapper">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="duplicate-audit-name" className="mb-2">
                New name
              </label>
              <Input
                id="duplicate-audit-name"
                value={name}
                onChange={onNewItemTyped}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              className="btn-success m-0"
              onClick={duplicate}
              loading={isResponseLoading}
            >
              Duplicate
            </Button>
          </Col>
        </Row>
      </Popup>
    </>
  );
};

export default DuplicateAuditButton;
