import React from 'react';
import Page from 'containers/page';
import LoginForm from 'forms/Login';

import { Redirect } from 'react-router';
import { useBackend } from '../../../providers/BackendProvider';

const Login = () => {
  const { accessToken } = useBackend();
  if (accessToken) return <Redirect to="/user/profile" />;
  return (
    <Page title="Login">
      <LoginForm />
    </Page>
  );
};

export default Login;
