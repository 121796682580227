import React, { useEffect, useState } from 'react';
import {
  CentralRisk,
} from 'interfaces';
import { Col, Row } from 'react-bootstrap';
import { fields as CommentFields } from 'forms/Comment';
import { fields as RecommendationFields } from 'forms/Reccomendation';
import { useFormContext } from '../../components/Form/context';
import CollapsibleItem from '../../components/CollapsibleItem';
import css from '../Issue/Issue.module.scss';
import FormFieldControl from '../../components/FormFieldControl';
import { FormFields } from '../../components/Form';
import TextEditor from '../../components/TextEditor';
import Comments from '../../components/List/Comments';
import Recommendations from '../../components/List/Recommendations';

export interface CentralRiskFormProps {
    name: string;
    value: CentralRisk;
}

export const fields: FormFields = {
  title: {
    label: 'Title',
  },
  description: {
    label: 'Description',
    component: TextEditor,
  },
  recommendations: {
    label: 'Recommendations',
    component: Recommendations,
    fields: RecommendationFields
  },
  comments: {
    label: 'Comments',
    component: Comments,
    fields: CommentFields,
  },
};

const CentralRiskForm: React.FunctionComponent<CentralRiskFormProps> = ({ name, value }) => {
  const [collapsed, setCollapsed] = useState<boolean>(!value.title);
  // const [title, setTitle] = useState<string>('Enter title'); // todo: sync title with state
  const {
    watch, collapseAllCb
  } = useFormContext();
  const watchType = watch('type');

  useEffect(() => {
    collapseAllCb(() => {
      setCollapsed(false);
    });
  }, [collapseAllCb]);

  return (
    <CollapsibleItem
      title={value.title || 'Enter title'}
      in={collapsed}
      className={css.cardBody}
    >
      <>
        <Row>
          <Col xs={12} md={12}>
            <FormFieldControl name={`${name}.title`} defaultValue={value.title} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <FormFieldControl
              name={`${name}.description`}
              defaultValue={value.description}
              isFocused
            />
          </Col>
          {watchType !== 0 ? (
            <Col xs={12} md={12}>
              <FormFieldControl
                name={`${name}.recommendations`}
                defaultValue={value.recommendations}
              />
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col>
            <FormFieldControl
              name={`${name}.comments`}
              defaultValue={value.comments}
            />
          </Col>
        </Row>
      </>
    </CollapsibleItem>
  );
};

export default CentralRiskForm;
