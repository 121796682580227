import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { EnumTypes } from '../services/EnumsService';
import { useBackend } from './BackendProvider';

export const EnumsContext = createContext<{
  enums: Array<any>;
  loading: boolean;
  fetch:() => void;
  filterEnums: (type: EnumTypes) => Array<any>;
  filterAllEnums: (type: EnumTypes) => Array<any>;
    }>({
      enums: [],
      loading: false,
      fetch: () => {},
      filterEnums: () => [],
      filterAllEnums: () => [],
    });

export const useEnums = () => useContext(EnumsContext);

export const useFetchEnums = () => {
  const { enumsService } = useBackend();
  const [enums, setEnums] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetch = useCallback(async () => {
    setLoading(true);
    const data = await enumsService.get();
    setEnums(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  return {
    fetch,
    loading,
    enums: enums || [],
    filterEnums: (type: EnumTypes) => [...(enums || [])]
      .sort((a: any, b: any) => a.key - b.key)
      .filter(
        (enumItem: any) => enumItem.active === 'Y' && enumItem.type === type,
      ),
    filterAllEnums: (type: EnumTypes) => [...(enums || [])]
      .sort((a: any, b: any) => a.key - b.key)
      .filter((enumItem: any) => enumItem.type === type),
  };
};

export const EnumsProvider: React.FC = (props) => {
  const context = useFetchEnums();
  const { children } = props;

  return (
    <EnumsContext.Provider value={context}>
      {children}
    </EnumsContext.Provider>
  );
};
