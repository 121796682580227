import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { getClassificatorValue, ReportTemplate, ReportTemplatePrefix } from 'interfaces';
import FormFieldControl from 'components/FormFieldControl';
import { CompanyMap, FieldProps } from '../interfaces';

const ChaptersField: React.FC<FieldProps> = () => (
  <Row>
    <Col>
      <FormFieldControl name="chapters" />
    </Col>
  </Row>
);

const disclaimer: CompanyMap = {
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix.HASHEX)]: () => null,
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix['0XGUARD'])]: ChaptersField,
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix.RUGDOG)]: ChaptersField,
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix.RAPID_LABS)]: ChaptersField,
};

export default disclaimer;
