import React from 'react';
import classnames from 'classnames';

import { Issue } from 'interfaces';

import List, { ListProps } from 'components/List';
import IssueForm from 'forms/Issue';

export interface IssuesListProps extends Omit<ListProps<Issue>, 'renderItem'> {}

const IssuesList: React.FC<IssuesListProps> = ({ className, ...props }) => {
  const classNames = classnames('issues-list', className);
  return (
    <List<Issue>
      className={classNames}
      {...props}
      component={IssueForm}
      addText="Add contract issue"
      initialValue={
        {
          resolution: 0,
          standard: false,
        } as Issue
      }
    />
  );
};

export default IssuesList;
