import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { components } from 'react-select';
import Select, { SelectProps } from './Select';
import { EnumTypes } from '../../services/EnumsService';
import { useEnums } from '../../providers/EnumsProvider';
import Button from '../Button';
import Popup from '../Popup/Popup';
import CreateContractTagForm from '../../forms/CreateContractTag';
import { getErrorMessage } from '../../helpers';
import { useBackend } from '../../providers/BackendProvider';

// eslint-disable-next-line react/display-name
const ContractTagsSelect: React.FC<SelectProps> = React.forwardRef(
  (props, ref) => {
    const { filterEnums, enums, fetch } = useEnums();
    const { enumsService } = useBackend();
    const [showCreate, setShowCreate] = useState<boolean>(false);
    const [isResponseLoading, setIsResponseLoading] = useState<boolean>(false);
    const [addValueFn, setAddValueFn] = useState<(() => { key: number }) | null
      >(null);

    const onSubmit = async ({ name }: any) => {
      if (isResponseLoading) return;
      setIsResponseLoading(true);
      try {
        const response = await enumsService.createOne(
          EnumTypes.CONTRACT_TAGS,
          name,
        );
        toast.success('Tag saved');
        await fetch();
        setAddValueFn(() => () => ({
          key: response.key,
        }));
      } catch (e) {
        toast.error(getErrorMessage(e));
      } finally {
        setIsResponseLoading(false);
        setShowCreate(false);
      }
    };

    const onCreateClose = () => {
      if (isResponseLoading) return;
      setShowCreate(false);
    };

    const options = useMemo(
      () => filterEnums(EnumTypes.CONTRACT_TAGS).map((item: any) => ({
        value: item.key,
        label: item.value,
      })),
      [enums],
    );

    // eslint-disable-next-line react/no-unstable-nested-components
    const SelectMenuButton = (selectButtonProps: any) => {
      const { children } = selectButtonProps;

      return (
        <components.MenuList {...selectButtonProps}>
          {children}
          <Button onClick={() => setShowCreate(true)}>Add new tag</Button>
        </components.MenuList>
      );
    };

    return (
      <>
        <Select
          components={{ MenuList: SelectMenuButton }}
          {...props}
          options={options}
          ref={ref}
          multiple
          multipleProp="contractTag"
          addValueFn={addValueFn}
        />
        <Popup showPopup={showCreate}>
          <CreateContractTagForm
            onSubmit={onSubmit}
            onCreateClose={onCreateClose}
            isSubmitting={isResponseLoading}
          />
        </Popup>
      </>
    );
  },
);

export default ContractTagsSelect;
