import React from 'react';
import classnames from 'classnames';

import { Contract } from 'interfaces';

import List, { ListProps } from 'components/List';
import ContractForm from 'forms/Contract';

export interface ContractsListProps
  extends Omit<ListProps<Contract>, 'renderItem'> {}

const ContractsList: React.FC<ContractsListProps> = ({
  className,
  ...props
}) => {
  const classNames = classnames('contracts-list', className);
  return (
    <List<Contract>
      className={classNames}
      {...props}
      component={ContractForm}
      addText="Add contract"
      sortable
    />
  );
};

export default ContractsList;
