import React from 'react';

import { useFormContext } from 'components/Form/context';

import Button, { ButtonProps } from 'components/Button/index';

type SubmitButtonProps = Omit<ButtonProps, 'type'> & { id?: string };

const SubmitButton: React.FC<SubmitButtonProps> = ({
  id = `${Date.now()}`,
  ...props
}) => {
  const { formState, onSubmit } = useFormContext();
  return (
    <Button
      onClick={onSubmit}
      id={id}
      loading={formState.isSubmitting}
      {...props}
    />
  );
};

export default SubmitButton;
