import React, { useEffect, useMemo, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { getErrorMessage } from 'helpers';

import { FormContext } from './context';

import './Form.css';

export const getFormFieldError = (key: string, errors: any) => {
  const keys = key.split('.');

  if (keys.length === 0) {
    return errors[key];
  }

  return keys.reduce((acc, k: string) => {
    // eslint-disable-next-line no-param-reassign
    acc = acc[k] === undefined ? {} : acc[k];

    return acc;
  }, errors);
};

export interface FormField {
  label?: string;
  validator?: any;
  type?: string;
  component?: any;
  fields?: FormFields;
}

export type FormFields = { [x: string]: FormField };

export const getFieldProps = (key: string, fields: FormFields, errors: any) => {
  const keys = key.split('.');
  let fieldProps: any = null;

  keys.forEach((currentKey) => {
    let keyParsed = key;
    try {
      keyParsed = JSON.parse(currentKey);
    } catch (e) {
      keyParsed = key;
    }
    if (typeof keyParsed !== 'string') return;
    let _fields = fields;
    if (fieldProps && fieldProps.fields) {
      _fields = fieldProps.fields;
    }
    const props = _fields[currentKey];

    if (!props) {
      fieldProps = null;
    } else {
      fieldProps = props;
    }
  });

  const error = getFormFieldError(key, errors);

  if (fieldProps) delete fieldProps.validator;
  return {
    ...fieldProps,
    name: key,
    error: error.message,
  };
};

export const extractResolver = (fields: FormFields) => {
  const validators = Object.keys(fields).reduce((acc: any, key: string) => {
    const fieldValidator = fields[key].validator;
    if (fieldValidator) {
      acc[key] = fieldValidator;
    }
    return acc;
  }, {});

  return yupResolver(yup.object().shape(validators));
};

export interface FormProps<T> {
  value?: T;
  onChange?: (value: T) => void;
  children: any;
  onSubmit?: (value: T, exit: boolean) => void;
  fields: FormFields;
  defaultValues?: any;
  silent?: boolean;
  prompt?: boolean;
}

const Form = <T extends {}>({
  children,
  onSubmit = () => {},
  fields,
  defaultValues,
  silent,
  prompt,
}: FormProps<T>) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const resolver = useMemo(() => extractResolver(fields), [fields]);
  const {
    control,
    register,
    formState,
    handleSubmit,
    getValues,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver,
    defaultValues,
  });

  const onError = (errors: any, event: any) => {
    toast.error('Form is not filled correctly');
    console.error('submit errors', errors, event, getValues(), defaultValues);
  };

  const [textEditorValueChangeFn, setTextEditorValueChangeFn] = useState<(() => (cb: any) => void) | null>(null);
  const [collapseAllCb, setCollapseAllCb] = useState<(() => (cb: () => void) => void)>(() => () => {});

  const setTextEditorValue = (...args: any[]) => {
    // @ts-ignore
    setValue(...args);
    setTextEditorValueChangeFn(
      () => (callback: any) => callback(args[0], args[1]),
    );
  };

  const collapseAll = () => {
    setCollapseAllCb(
      () => (callback: () => void) => callback(),
    );
  };

  const onSubmitForm = handleSubmit(async (data: any, event) => {
    // @ts-ignore
    const exitMode = event?.nativeEvent.target.id === 'submit-exit';
    try {
      setIsSubmitting(true);
      await onSubmit(data, exitMode);
      if (!silent) toast.success('Success');
    } catch (e) {
      toast.error(getErrorMessage(e));
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  }, onError);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const context = {
    register,
    control,
    formState,
    onSubmit: onSubmitForm,
    fields,
    watch,
    isSubmitting,
    getValues,
    setValue,
    setTextEditorValue,
    textEditorValueChangeFn,
    collapseAll,
    collapseAllCb
  };

  const showPrompt = prompt && !isSubmitting && formState.isDirty;

  useEffect(() => {
    if (showPrompt) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [showPrompt]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  return (
    <FormContext.Provider value={context}>
      <div className="form">
        {showPrompt && (
          <Prompt
            when={showPrompt}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        )}
        {children}
      </div>
    </FormContext.Provider>
  );
};

export default Form;
