import { SelectOption } from 'interfaces';
// @ts-ignore
import refractor from 'refractor/core.js';
// @ts-ignore
import solidity from 'refractor/lang/solidity';
import {
  tokenize, markEdits
// @ts-ignore
} from 'react-diff-view';

refractor.register(solidity);

export const tokenizeFn = (hunks: any, oldSource: string) => {
  if (!hunks) {
    return undefined;
  }

  const options = {
    highlight: true,
    enhancers: [markEdits(hunks, { type: 'block' })],
    language: 'solidity',
    refractor,
    oldSource,
  };
  return tokenize(hunks, options);
};

export const isObjectEmpty = (obj: any) => Object.keys(obj).length === 0;

export function getNotEmptyValue(...args: any[]) {
  for (let i = 0; i < args.length; i += 1) {
    if (args[i] !== undefined && args[i] !== null) {
      return args[i];
    }
  }
  return undefined;
}

export const getErrorMessage = (e: any) => {
  if (e.response?.data?.message) return e.response.data.message;
  return e.message;
};

export const mapEnumToOptions = (
  enumObj: { [k: string]: string } | string[],
): SelectOption[] => Object.values(enumObj).map(
  (value) => ({
    value,
    label: value,
  } as SelectOption),
);

function padLeft(n: number, chr?: string, base?: number) {
  const len = String(base || 10).length - String(n).length + 1;
  return len > 0 ? new Array(len).join(chr || '0') + n : n;
}

export const subtractOffsetDate = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const hours = date.getUTCHours();
  date.setUTCHours(hours - offset / 60);
  return date;
};

export const addOffsetDate = (input: string | Date | undefined): Date | undefined => {
  if (!input) return undefined;
  const date = typeof input === 'string' ? new Date(input) : input;
  const offset = date.getTimezoneOffset();
  const hours = date.getUTCHours();
  date.setUTCHours(hours + offset / 60);
  return date;
};

export const dformat = (
  dateStr: Date | string | undefined,
  time: boolean = true,
) => {
  if (!dateStr) return '';
  const d = typeof dateStr === 'string' ? new Date(dateStr) : dateStr;

  return (
    [
      padLeft(d.getDate()),
      padLeft(d.getMonth() + 1),
      padLeft(d.getFullYear()),
    ].join('/')
    + (time
      ? ` ${[padLeft(d.getHours()), padLeft(d.getMinutes())].join(':')}`
      : '')
  );
};

export const getExtractEnumsFactory = (enums: Array<any>, valueProp: string = 'key', labelProp: string = 'value') => () => enums.map((item: any) => ({
  value: item[valueProp],
  label: item[labelProp],
}));

export const getUid = () => Date.now().toString(36) + Math.random().toString(36).substr(2);

export const isEmpty = (obj:any):boolean => Object.keys(obj).length === 0;

export const getDataFromStorage = (name: string) => {
  const obj = JSON.parse(localStorage.getItem('tableSettings') || '{}');
  if (name === 'filter' && JSON.stringify(obj.filter) !== '{}') {
    return obj[name];
  }
  return Number(obj[name]) ? Number(obj[name]) : obj[name];
};

export const getOptionFromAuditFilter = (
  filter: any,
  statusOtions: SelectOption[],
) => {
  let res;
  if (filter) {
    if (Array.isArray(filter)) {
      const statusArr: SelectOption[] = [];
      statusOtions.forEach((item) => {
        if (filter.includes(item.value)) {
          statusArr.push(item);
        }
      });
      res = statusArr;
    } else {
      statusOtions.forEach((item) => {
        if (filter === item.value) {
          res = item;
        }
      });
    }
  }
  return res;
};

export const setQueryParams = ({
  page,
  limit,
  sortBy,
  sortDirection,
  filter,
}: {
  page: number,
  limit: number,
  sortBy: string,
  sortDirection: string,
  filter: any,
}): string => {
  const query = new URLSearchParams();
  if (filter && JSON.stringify(filter) !== '{}') {
    Object.entries(filter).forEach(([filterKey, filterValue]) => {
      if (Array.isArray(filterValue)) {
        if (filterValue.length > 0) {
          query.set(filterKey, `[${filterValue.join(',')}]`);
        }
      } else if (filterValue) {
        query.set(filterKey, String(filterValue));
      }
    });
  }
  if (sortBy && sortDirection) {
    query.set('sort', `${sortBy},${sortDirection}`);
  } else {
    query.delete('sort');
  }
  if (page && limit) {
    query.set('page', String(page));
    query.set('limit', String(limit));
  }
  return decodeURIComponent(query.toString());
};

export const getQueryParams = (
  query: URLSearchParams
): {
  filter: any;
  page: number,
  limit: number,
  sortBy: string,
  sortDirection: string,
} => {
  try {
    let filter = {};
    let sortBy = '';
    let sortDirection = '';
    let page = 1;
    let limit = 10;
    query.forEach((value, key) => {
      if (key === 'sort' && value !== 'null') {
        const sorterArr = value.split(',');
        [sortBy, sortDirection] = sorterArr;
      } else if (key === 'page') {
        page = Number(value);
      } else if (key === 'limit') {
        limit = Number(value);
      } else if (value.includes('[')) {
        const arrString = value.slice(1, value.length - 1).split(',').map((item) => Number(item));
        filter = {
          ...filter,
          [key]: arrString,
        };
      } else {
        filter = {
          ...filter,
          [key]: Number(value) ? Number(value) : value,
        };
      }
    });
    return {
      filter,
      page,
      limit,
      sortBy,
      sortDirection,
    };
  } catch {
    return {
      filter: {},
      page: 1,
      limit: 10,
      sortBy: '',
      sortDirection: '',
    };
  }
};
