import React from 'react';
import Page from 'containers/page';
import IssuesTable from '../../components/Table/IssuesTable';

const Issues = () => (
  <Page title="Issues">
    <IssuesTable />
  </Page>
);

export default Issues;
