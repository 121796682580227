import ProtectedService from './ProtectedService';

export const TemplateTypes: {[x: string]: number} = {
  introduction: 1,
  publishInWork: 2,
  publishFinal: 3,
};

export enum Templates {
  introduction = 'introduction',
  publishInWork = 'publishInWork',
  publishFinal = 'publishFinal',
}

export enum TemplateLabel {
  introduction = 'Introduction template',
  publishInWork = 'Publish template when status in work',
  publishFinal = 'Publish template when status final',
}

class TemplatesService extends ProtectedService {
    baseUrl = '/templates';

    editOne = async (value: string, type: string) => this.post({ template: value }, type)
}

export default TemplatesService;
