import React, { useMemo } from 'react';
import { Col, Row, CloseButton } from 'react-bootstrap';
import * as yup from 'yup';
import Form, { FormField } from 'components/Form';
import SubmitButton from 'components/Button/SubmitButton';
import FormFieldControl from 'components/FormFieldControl';
import SeveritySelect from '../../components/Select/SeveritySelect';
import ContractTypeSelect from '../../components/Select/ContractTypeSelect';
import TextEditor from '../../components/TextEditor';
import { StandardIssue } from '../../interfaces';
import ContractTagsSelect from '../../components/Select/ContractTagsSelect';

const getFields = (): { [x: string]: FormField } => ({
  name: {
    label: 'Title',
    validator: yup.string().required(),
  },
  severity: {
    label: 'Severity',
    component: SeveritySelect,
    validator: yup.number().required(),
  },
  contractType: {
    label: 'Contract Type',
    component: ContractTypeSelect,
    validator: yup.number().required(),
  },
  description: {
    label: 'Description',
    component: TextEditor,
  },
  tags: {
    label: 'Tags',
    component: ContractTagsSelect,
  },
});

interface StandardIssueFormProps {
  onCreateClose: any;
  onSubmit: any;
  isSubmitting?: boolean;
  editMode?: boolean;
  selectMode?: boolean;
  silent?: boolean;
  state?: StandardIssue;
}

const getDefault = (): {
  name?: string;
  severity?: number;
  contractType?: number;
  description?: any;
  tags: Array<any>;
} => ({
  tags: [],
});

const StandardIssueForm: React.FC<StandardIssueFormProps> = ({
  editMode = false,
  state = getDefault(),
  onCreateClose,
  onSubmit,
  isSubmitting = false,
  selectMode = false,
  silent = true,
}) => {
  const fields = useMemo(() => getFields(), []);

  return (
    <Form
      onSubmit={onSubmit}
      fields={fields}
      defaultValues={state}
      silent={silent}
    >
      <Row className="mb-3">
        <Col xs={12} md={12} className="d-flex justify-content-end">
          <CloseButton onClick={onCreateClose} />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={12} md={12}>
          <FormFieldControl name="name" defaultValue={state.name} />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={12} md={4}>
          <FormFieldControl name="severity" defaultValue={state.severity} />
        </Col>
        <Col xs={12} md={4}>
          <FormFieldControl
            name="contractType"
            defaultValue={state.contractType}
          />
        </Col>
        <Col xs={12} md={4}>
          <FormFieldControl name="tags" defaultValue={state.tags} />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={12} md={12}>
          <FormFieldControl
            name="description"
            defaultValue={state.description}
            isFocused
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={12} md={12} className="d-flex justify-content-end">
          <SubmitButton loading={isSubmitting} className="btn-success m-0">
            {/* eslint-disable-next-line no-nested-ternary */}
            {selectMode ? 'Select' : editMode ? 'Edit' : 'Create'}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
};

export default StandardIssueForm;
