import React from 'react';
import { Container } from 'react-bootstrap';

import './Page.css';

interface PageProps {
  title?: string;
  toolbar?: any;
}

const Page: React.FC<PageProps> = ({ title, children, toolbar = null }) => (
  <div className="page">
    <Container>
      <div className="page__header">
        {title && <div className="page__title">{title}</div>}
        {toolbar && <div className="page__toolbar">{toolbar}</div>}
      </div>
    </Container>
    <div className="page__content">
      <Container>{children}</Container>
    </div>
  </div>
);

export default Page;
