import React, { useState } from 'react';
import { FaTimes, FaUndo } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Button from 'components/Button/index';
import { CloseButton, Col, Row } from 'react-bootstrap';
import { getErrorMessage } from '../../../helpers';
import Popup from '../../Popup/Popup';
import { useBackend } from '../../../providers/BackendProvider';

const DeleteStandardIssueButton: React.FC<{ id: number; isDeleted: boolean; update: () => void }> = ({
  id,
  isDeleted,
  update,
}) => {
  const { standardIssuesService } = useBackend();
  const [isResponseLoading, setIsResponseLoading] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const disableItem = async () => {
    if (isResponseLoading) return;
    setIsResponseLoading(true);
    try {
      if (isDeleted) {
        await standardIssuesService.post(null, `${id}/restore`);
      } else {
        await standardIssuesService.delete(null, `${id}`);
      }
      toast.success(`Issue ${isDeleted ? 'enabled' : 'disabled'}`);
      update();
      setShowPopup(false);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsResponseLoading(false);
    }
  };

  const onDisableClose = (force = false) => {
    if (!force && isResponseLoading) return;
    setShowPopup(false);
  };

  return (
    <>
      <Button
        size="sm"
        title={isDeleted ? 'Enable' : 'Disable'}
        variant={isDeleted ? 'primary' : 'danger'}
        onClick={() => setShowPopup(true)}
      >
        {isDeleted ? <FaUndo /> : <FaTimes />}
      </Button>
      <Popup showPopup={showPopup} half>
        <Row className="mb-4">
          <Col className="d-flex justify-content-end">
            <CloseButton onClick={() => onDisableClose()} />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <p className="h5 mb-2">
              {`Do you want to ${
                isDeleted ? 'Enable' : 'Disable'
              } this issue?`}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              className={`m-0 btn-${
                isDeleted ? 'success' : 'danger'
              }`}
              onClick={disableItem}
              loading={isResponseLoading}
            >
              {isDeleted ? 'Enable' : 'Disable'}
            </Button>
          </Col>
        </Row>
      </Popup>
    </>
  );
};

export default DeleteStandardIssueButton;
