import React, { useMemo } from 'react';
import { Col, Row, CloseButton } from 'react-bootstrap';
import * as yup from 'yup';
import Form, { FormField } from 'components/Form';
import FormFieldControl from 'components/FormFieldControl';
import SubmitButton from '../../components/Button/SubmitButton';

interface PublishDataFormProps {
  onClose: any;
  onSubmit: any;
  state?: {
    path?: string;
    name?: string;
  };
  isSubmitting?: boolean;
  btnName: string;
}

const getFields = (): { [x: string]: FormField } => ({
  path: {
    label: 'Path',
    validator: yup.string().required()
  },
  name: {
    label: 'File name',
    validator: yup.string().required()
  },
});

const getDefault = (): {
  path?: string;
  name?: string;
} => ({
});

const PublishDataForm: React.FC<PublishDataFormProps> = ({
  onSubmit, onClose, state = getDefault(), isSubmitting, btnName
}) => {
  const fields = useMemo(() => getFields(), []);

  return (
    <Form
      onSubmit={onSubmit}
      fields={fields}
      defaultValues={state}
      silent
    >
      <Row className="mb-3">
        <Col xs={12} md={12} className="d-flex justify-content-end">
          <CloseButton onClick={onClose} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormFieldControl name="path" defaultValue={state.path} />
        </Col>
        <Col>
          <FormFieldControl name="name" defaultValue={state.name} />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={12} md={12} className="d-flex justify-content-end">
          <SubmitButton loading={isSubmitting} className="btn-success m-0">
            {btnName}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
};

export default PublishDataForm;
