import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import styles from './Logout.module.scss';
import { useBackend } from '../../../providers/BackendProvider';

const Logout = () => {
  const { authService } = useBackend();
  const [timer, setTimer] = useState<number>(3);
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(async () => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        await authService.logout();
        history.push('/user/login');
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <div className={styles.wrapper}>
      <Alert variant="danger">{`You will be logged out in ${timer} second${timer > 1 ? 's' : ''}...`}</Alert>
    </div>
  );
};

export default Logout;
