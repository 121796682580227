import React from 'react';
import classnames from 'classnames';

import { AuditChapter } from 'interfaces';

import List, { ListProps } from 'components/List';
import AuditChapterForm from '../../../forms/AuditChapter';

export interface AuditChaptersListProps
  extends Omit<ListProps<AuditChapter>, 'renderItem'> {}

const AuditChaptersList: React.FC<AuditChaptersListProps> = ({
  className,
  ...props
}) => {
  const classNames = classnames('chapters-list', className);
  return (
    <List<AuditChapter>
      className={classNames}
      {...props}
      component={AuditChapterForm}
      addText="Add chapter"
      sortable
    />
  );
};

export default AuditChaptersList;
