import React from 'react';
import Page from 'containers/page';
import AuditForm from 'forms/Audit';

const AddAudit = () => (
  <Page title="Add Audit">
    <AuditForm />
  </Page>
);

export default AddAudit;
