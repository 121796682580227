import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Row, Col, CloseButton } from 'react-bootstrap';
import Button from 'components/Button/index';
import { AuditInTable } from '../../../interfaces';
import { getErrorMessage } from '../../../helpers';
import { useUser } from '../../../providers/UserProvider';
import Popup from '../../Popup/Popup';
import { useBackend } from '../../../providers/BackendProvider';

const EditAuditButton: React.FC<{ audit: AuditInTable }> = ({ audit }) => {
  const history = useHistory();
  const profile = useUser();
  const { auditService } = useBackend();

  const editors = useMemo(
    () => audit.editors?.split(',').map(Number) || [],
    [audit.editors],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const assign = async (editMode: boolean) => {
    if (!audit.id) return;
    setLoading(true);
    try {
      if (editMode && !audit.currentUser) {
        await auditService.assign(audit.id);
      }
      history.push(`/audits/edit/${audit.id}`);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };
  const onPopupClose = () => setShowPopup(false);

  return (
    <>
      <Button
        size="sm"
        title="View/Edit"
        variant="dark"
        onClick={() => setShowPopup(true)}
        loading={loading}
      >
        <FaRegEdit />
      </Button>
      <Popup showPopup={showPopup}>
        <Row className="mb-4">
          <Col className="d-flex justify-content-end">
            <CloseButton onClick={onPopupClose} />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <p className="h5">Do you want to edit this report?</p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            {!audit.currentUser || audit.currentUser !== profile?.id ? (
              <Button className="m-0 mx-2" onClick={() => assign(false)}>
                View
              </Button>
            ) : null}
            {(!audit.currentUser || audit.currentUser === profile?.id)
            && editors.includes(profile?.id || -1) ? (
              <Button className="btn-success m-0" onClick={() => assign(true)}>
                Edit
              </Button>
              ) : null}
          </Col>
        </Row>
      </Popup>
    </>
  );
};

export default EditAuditButton;
