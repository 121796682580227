import * as yup from 'yup';

import {
  getClassificatorValue,
  Profile,
  ReportTemplate,
  ReportTemplatePrefix,
} from 'interfaces';
import { FormFields } from 'components/Form';
import { fields as ContractFields } from 'forms/Contract';
import { fields as AppendixFields } from 'forms/Appendix';
import { fields as AuditChapterFields } from 'forms/AuditChapter';

import DateInput from 'components/DateInput';
import AuditStatusSelect from 'components/Select/AuditStatusSelect';
import AuditTypeSelect from 'components/Select/AuditTypeSelect';
import NetworkSelect from 'components/Select/NetworkSelect';
import ContractLanguageSelect from 'components/Select/ContractLanguageSelect';
import TextEditor from 'components/TextEditor';
import Contracts from 'components/List/Contracts';
import AuditChaptersList from 'components/List/AuditChapters';
import AppendixList from 'components/List/AppendixList';
import Checkbox from 'components/Checkbox/Checkbox';
import IconInput from 'components/IconInput';
import CentralizationLevelSelect from 'components/Select/CentralizationLevelSelect';
import ProjectCentralRiskSelect from 'components/Select/ProjectCentralRiskSelect';

const getFields = (profile: Profile | null): FormFields => {
  const fields: FormFields = {
    type: {
      label: 'Audit type',
      component: AuditTypeSelect,
    },
    status: {
      label: 'Audit status',
      component: AuditStatusSelect,
    },
    dateStart: {
      label: 'Audit start date',
      component: DateInput,
      validator: yup.date().required(),
    },
    dateEnd: {
      label: 'Audit end date',
      component: DateInput,
      validator: yup
        .date()
        .min(yup.ref('dateStart'), "end date can't be before start date"),
    },
    projectName: {
      label: 'Project name',
      validator: yup.string().required(),
    },
    projectUrl: {
      label: 'Project URL',
      validator: yup.string().url().nullable(),
    },
    networks: {
      label: 'Project networks',
      validator: yup.array().min(1, 'Minimum 1 network is required').required(),
      component: NetworkSelect,
    },
    projectLanguage: {
      label: 'Language',
      validator: yup.number().required(),
      component: ContractLanguageSelect,
    },
    centralizationLevel: {
      label: 'Level of Centralization',
      validator: yup.number().nullable(),
      component: CentralizationLevelSelect,
    },
    projectCentralRisk: {
      label: 'Project Centralization Risk',
      validator: yup.number().nullable(),
      component: ProjectCentralRiskSelect,
    },
    projectTelegram: {
      label: 'Telegram',
      validator:
        yup
          .string()
          .nullable()
          // transform input value to null if it equal empty string to avoid error
          .transform((_, val) => (val === '' ? null : val))
    },
    projectTwitter: {
      label: 'Twitter',
      validator:
        yup
          .string()
          .nullable()
          .transform((_, val) => (val === '' ? null : val))
    },
    projectLinkedin: {
      label: 'Linkedin',
      validator:
       yup
         .string()
         .nullable()
         .transform((_, val) => (val === '' ? null : val))
    },
    projectMedium: {
      label: 'Medium',
      validator:
       yup
         .string()
         .nullable()
         .transform((_, val) => (val === '' ? null : val))
    },
    projectGithub: {
      label: 'Github',
      validator:
       yup
         .string()
         .nullable()
         .transform((_, val) => (val === '' ? null : val))
    },
    projectCoinMarketCap: {
      label: 'CoinMarketCap',
      validator:
              yup
                .string()
                .nullable()
                .transform((_, val) => (val === '' ? null : val))
    },
    slug: {
      label: 'Slug',
    },
    coinCodexSlug: {
      label: 'CoinCodexSlug'
    },
    isPrivate: {
      label: 'Private',
      validator: yup.boolean().required(),
      component: Checkbox,
    },
    isTop: {
      label: 'Top',
      validator: yup.boolean(),
      component: Checkbox,
    },
    isDeployed: {
      label: 'Deployed',
      validator: yup.boolean(),
      component: Checkbox,
    },
    projectIcon: {
      label: 'Project icon',
      component: IconInput,
    },
    description: {
      label: 'Overview',
      validator: yup.object().required(),
      component: TextEditor,
    },
    generalCentralRisks: {
      label: 'General centralization risks',
      validator: yup.object().nullable(),
      component: TextEditor,
    },
    contracts: {
      label: 'Contracts',
      component: Contracts,
      validator: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().required('Contract title is required'),
            issues: yup.array().of(
              yup.object().shape({
                name: yup.string().required('Issue name is required'),
                severity: yup.number().required('Issue severity is required')
              })
            ),
            centralRisks: yup.array().of(
              yup.object()
            )
          }),
        )
        .required(),
      fields: ContractFields,
    },
    chapters: {
      label: 'Chapters',
      component: AuditChaptersList,
      validator: yup.array().of(
        yup.object().shape({
          title: yup.string().required('Some text is required'),
        }),
      ),
      fields: AuditChapterFields,
    },
    appendixes: {
      label: 'Appendixes',
      component: AppendixList,
      validator: yup.array().of(
        yup.object().shape({
          title: yup.string().required('Some text is required'),
        }),
      ),
      fields: AppendixFields,
    },
  };

  if (
    profile?.reportType === getClassificatorValue(ReportTemplate, ReportTemplatePrefix.HASHEX)
  ) {
    fields.disclaimer = {
      validator: yup.object().required(),
      component: TextEditor,
    };
  }

  return fields;
};

export default getFields;
