import React from 'react';
import type { CentralRisk } from 'interfaces';
import type { ListProps } from 'components/List';
import List from 'components/List';
import classNames from 'classnames';
import CentralRiskForm from 'forms/CentralRisk';

export interface CentralRiskProps extends Omit<ListProps<CentralRisk>, 'renderItem'> {}

const CentralRisksList: React.FunctionComponent<CentralRiskProps> = ({ className, ...props }) => (
  (
    <List<CentralRisk>
      className={classNames('central-risks-list', className)}
      {...props}
      component={CentralRiskForm}
      addText="Add centralization risk"
    />
  )
);

export default CentralRisksList;
