import React from 'react';
import FormGroup from 'components/FormGroup';

import { useFormContext } from 'components/Form/context';
import { getFieldProps } from 'components/Form';

interface FormFieldControlProps {
  name: string;
  defaultValue?: any;
  onKeyDown?: (e: KeyboardEvent) => void;
  isFocused?: boolean;
}

const FormFieldControl: React.FC<FormFieldControlProps> = ({
  name,
  defaultValue,
  onKeyDown,
  isFocused,
}) => {
  const {
    formState: { errors },
    fields,
    register,
    control,
  } = useFormContext();
  const fieldProps = getFieldProps(name, fields, errors);
  const args = !fieldProps.fields && { ...register(name) };
  return (
    <FormGroup
      {...fieldProps}
      {...args}
      defaultValue={defaultValue}
      control={control}
      onKeyDown={onKeyDown}
      isFocused={isFocused}
    />
  );
};

export default FormFieldControl;
