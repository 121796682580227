import React from 'react';
import classnames from 'classnames';

import './Loader.css';

interface ILoaderProps {
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

const Loader: React.FC<ILoaderProps> = ({ size = 'md', className }) => {
  const classNames = classnames(
    'loader',
    {
      [`loader--${size}`]: size,
    },
    className,
  );
  return <div className={classNames} />;
};

export default Loader;
