import React, { useCallback, useEffect, useMemo } from 'react';
import { createReactEditorJS } from 'react-editor-js';
import cn from 'classnames';

// @ts-ignore
import Table from '@editorjs/table';
// @ts-ignore
import List from '@editorjs/list';
// @ts-ignore
import Code from '@editorjs/code';
// @ts-ignore
import Header from '@editorjs/header';
// @ts-ignore
import Quote from '@editorjs/quote';
// @ts-ignore
import LinkTool from '@editorjs/link';
// @ts-ignore
import Marker from '@editorjs/marker';
// @ts-ignore
import InlineCode from '@editorjs/inline-code';
import { getUid } from '../../helpers';

import './TextEditor.scss';
import { useFormContext } from '../Form/context';

const EDITOR_JS_TOOLS = {
  marker: Marker,
  inlineCode: InlineCode,
  table: Table,
  list: {
    class: List,
    inlineToolbar: true // or ['bold', 'link']
  },
  code: Code,
  linkTool: LinkTool,
  header: Header,
  quote: Quote
};

interface TextEditorProps {
  name: string;
  value: any;
  isFocused?: boolean;
  defaultValue?: any;
  onChange: (value: any) => void;
}

const EditorJS = createReactEditorJS();

// eslint-disable-next-line react/display-name
const TextEditor: React.FC<TextEditorProps> = ({
  onChange, name, defaultValue, isFocused
}) => {
  const {
    textEditorValueChangeFn,
    formState: { errors }
  } = useFormContext();
  const editorCore = React.useRef<any>(null);

  const handleChange = async () => {
    const data = await editorCore.current.save();

    onChange({
      type: 'change',
      target: {
        name,
        value: data
      }
    });
  };

  const holder = useMemo(() => getUid(), [textEditorValueChangeFn]);

  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const firstError = React.useMemo(() => Object.keys(errors)[0], [errors]);

  useEffect(() => {
    if (name === firstError) {
      editorCore.current?._editorJS.focus();
    }
  }, [errors]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    textEditorValueChangeFn
      && textEditorValueChangeFn((fieldName: any, fieldValue: any) => {
        if (fieldName === name) {
          setTimeout(() => {
            if (fieldValue) {
              // @ts-ignore
              editorCore.current?._editorJS.render(fieldValue);
            } else {
              // @ts-ignore
              editorCore.current?._editorJS.clear();
            }
          });
        }
      });
  }, [textEditorValueChangeFn]);

  return (
    <div className={cn('text-editor-wrapper', { 'text-editor-wrapper_error': errors[name], 'focus-editor': isFocused })}>
      <EditorJS
        holder={holder}
        onInitialize={handleInitialize}
        data={defaultValue}
        onChange={handleChange}
        tools={EDITOR_JS_TOOLS}
      >
        <div id={holder} />
      </EditorJS>
    </div>
  );
};

export default TextEditor;
