import React, { useMemo } from 'react';
import { Col, Row, CloseButton } from 'react-bootstrap';
import * as yup from 'yup';
import Form, { FormField } from 'components/Form';
import SubmitButton from 'components/Button/SubmitButton';
import FormFieldControl from 'components/FormFieldControl';

const getFields = (): { [x: string]: FormField } => ({
  name: {
    label: 'Name',
    validator: yup.string().required()
  }
});

interface StandardIssueFormProps {
  onCreateClose: any;
  onSubmit: any;
  isSubmitting?: boolean;
  silent?: boolean;
}

const CreateContractTagForm: React.FC<StandardIssueFormProps> = ({
  onCreateClose,
  onSubmit,
  isSubmitting = false,
  silent = true
}) => {
  const fields = useMemo(() => getFields(), []);

  return (
    <Form onSubmit={onSubmit} fields={fields} silent={silent}>
      <Row className="mb-4">
        <Col className="d-flex justify-content-end">
          <CloseButton onClick={onCreateClose} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <FormFieldControl name="name" />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end">
          <SubmitButton className="btn-success m-0" loading={isSubmitting}>Create</SubmitButton>
        </Col>
      </Row>
    </Form>
  );
};

export default CreateContractTagForm;
