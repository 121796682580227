import React from 'react';
import {
  Button as BSButton,
  ButtonProps as BSButtonProps,
} from 'react-bootstrap';
import classnames from 'classnames';

import Loader from 'components/Loader';

import './Button.css';

export interface ButtonProps extends BSButtonProps {
  loading?: boolean;
  circle?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  className,
  children,
  loading,
  circle,
  size,
  ...props
}) => {
  let classNames = classnames('button', className);
  if (circle) {
    classNames = classnames('btn-circle', classNames);
  }
  return (
    <BSButton size={size} className={classNames} {...props}>
      {loading ? <Loader size={size} /> : children}
    </BSButton>
  );
};

export default Button;
