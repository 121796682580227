import { AuditStatus, AuditStatusPrefix, getClassificatorValue } from 'interfaces';
import { useEffect, useState } from 'react';
import { TemplateTypes } from 'services/TemplatesService';
import { useBackend } from '../providers/BackendProvider';

const getTemplateType = (
  auditStatus: number | undefined
) => {
  if (auditStatus === getClassificatorValue(AuditStatus, AuditStatusPrefix.IN_WORK)) return TemplateTypes.publishInWork;
  if (auditStatus === getClassificatorValue(AuditStatus, AuditStatusPrefix.FINAL)) return TemplateTypes.publishFinal;
  return '';
};

export const usePublishTemplate = (auditStatus: number) => {
  const { templatesService } = useBackend();
  const [publishTemplate, setPublishTemplate] = useState<any>();

  useEffect(() => {
    const auditTemplateType = getTemplateType(auditStatus);
    const fetchTemplate = async (templateType: number) => {
      const newTemplate: any = await templatesService.get(null, `${templateType}`);
      setPublishTemplate(newTemplate.template);
    };

    if (auditTemplateType) fetchTemplate(auditTemplateType);
  }, []);

  return {
    publishTemplate
  };
};
