import React, { useState, useEffect } from 'react';
import { IssueInTable } from 'interfaces';
import { toast } from 'react-toastify';
import { useUser } from 'providers/UserProvider';
import IssuesTable from './IssuesTable';
import { useBackend } from '../../../providers/BackendProvider';
import { getErrorMessage } from '../../../helpers';

const Container: React.FC<{}> = () => {
  const { issuesService } = useBackend();
  const [loading, setLoading] = useState<boolean>(false);
  const [issues, setIssues] = useState<IssueInTable[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [filter, setFilter] = useState<any>({});
  const [limit, setLimit] = useState<number>(20);
  const [page, setPage] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<string>('desc');
  const user = useUser();

  const fetchIssues = async (
    nPage: number = page,
    nLimit: number = limit,
    nSortBy: string = sortBy,
    nSortDirection: string = sortDirection,
    nFilter: any = filter,
  ) => {
    if (user?.id) {
      setLoading(true);

      try {
        const [newIssues, count] = await issuesService.getList(
          nPage,
          nLimit,
          nSortBy,
          nSortDirection,
          nFilter,
        );

        setIssues(newIssues);
        setPageCount(Math.ceil(count / nLimit));
      } catch (e) {
        toast.error(getErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const changeSort = (sort: string, direction: string) => {
    setSortBy(sort);
    setSortDirection(direction);
    fetchIssues(page, limit, sort, direction);
  };

  let filterInputTimeout: any;

  const onFilterChange = (
    field: string,
    value: any,
    needTimeout: boolean = false,
  ) => {
    if (needTimeout && filterInputTimeout) clearTimeout(filterInputTimeout);
    filterInputTimeout = setTimeout(() => {
      const nFilter = {
        ...filter,
        [field]: value,
      };
      setPage(0);
      setFilter(nFilter);
      fetchIssues(0, limit, sortBy, sortDirection, nFilter);
    }, 500);
  };

  const handlePageClick = (e: { selected: number }) => {
    setPage(e.selected);
    fetchIssues(e.selected);
  };

  const onLimitChange = (value: number) => {
    setPage(0);
    setLimit(value);
    fetchIssues(0, value);
  };

  useEffect(() => {
    fetchIssues();
  }, []);

  return (
    <IssuesTable
      loading={loading}
      issues={issues}
      fetchIssues={fetchIssues}
      sortBy={sortBy}
      sortDirection={sortDirection}
      changeSort={changeSort}
      onFilterChange={onFilterChange}
      page={page}
      pageCount={pageCount}
      handlePageClick={handlePageClick}
      limit={limit}
      onLimitChange={onLimitChange}
    />
  );
};

export default Container;
