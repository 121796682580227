import React from 'react';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import Form, { FormField } from 'components/Form';
import { useFormContext } from 'components/Form/context';
import SubmitButton from 'components/Button/SubmitButton';
import FormFieldControl from 'components/FormFieldControl';
import { useBackend } from '../../providers/BackendProvider';

const fields: { [x: string]: FormField } = {
  login: {
    label: 'User',
    validator: yup.string().required(),
  },
  pwd: {
    label: 'Current password',
    type: 'password',
    validator: yup.string().required('Enter your current password'),
  },
  newPwd: {
    label: 'New password',
    type: 'password',
    validator: yup
      .string()
      .notOneOf(['1234'], 'Default password')
      .required('Password is required'),
  },
  confirmedNewPwd: {
    label: 'Confirm new password',
    type: 'password',
    validator: yup
      .string()
      .oneOf([yup.ref('newPwd'), null], 'Passwords must match')
      .required('Password confirm is required'),
  },
};

const FormContent = () => {
  const { onSubmit } = useFormContext();

  const onKeydown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      onSubmit();
    }
  };

  return (
    <>
      <FormFieldControl name="login" onKeyDown={onKeydown} />
      <FormFieldControl name="pwd" onKeyDown={onKeydown} />
      <FormFieldControl name="newPwd" onKeyDown={onKeydown} />
      <FormFieldControl name="confirmedNewPwd" onKeyDown={onKeydown} />
      <SubmitButton>Change Password</SubmitButton>
    </>
  );
};

const ChangePasswordForm = () => {
  const { authService } = useBackend();
  const history = useHistory();

  const onSubmit = async (data: any) => {
    await authService.changePassword(data);
    history.push('/audits');
  };

  return (
    <Form onSubmit={onSubmit} fields={fields}>
      <FormContent />
    </Form>
  );
};

export default ChangePasswordForm;
