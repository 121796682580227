import React from 'react';

import Page from 'containers/page';

import ProfileInfo from 'components/ProfileInfo';

const Profile = () => (
  <Page title="User Profile">
    <ProfileInfo />
  </Page>
);

export default Profile;
