import React, { useState, useCallback } from 'react';
import { FaDownload } from 'react-icons/fa';
import { toast } from 'react-toastify';

import Button from 'components/Button/index';
import { getErrorMessage } from 'helpers';
import {
  getClassificatorValue,
  AuditStatus,
  AuditStatusPrefix,
  ReportTemplate,
  ReportTemplatePrefix,
} from 'interfaces';
import { useUser } from '../../../providers/UserProvider';
import { useBackend } from '../../../providers/BackendProvider';

interface DownloadTextReportButtonProps {
  id: number;
  short?: boolean;
  name: string;
  status?: AuditStatus;
}

const prepareName = (name: string) => name.replaceAll(/\s/g, '-');

const getFilename = (
  name: string,
  reportTemplate?: number,
  status?: AuditStatus,
) => {
  const preparedName = prepareName(name);
  const isDraft = status === getClassificatorValue(AuditStatus, AuditStatusPrefix.IN_WORK);
  const isHashex = reportTemplate
    === getClassificatorValue(ReportTemplate, ReportTemplatePrefix.HASHEX);
  const filename = `${preparedName}_${
    // eslint-disable-next-line no-nested-ternary
    isHashex && isDraft ? 'preliminary-' : !isHashex ? 'final-' : ''
  }audit-report_${Date.now()}.txt`;

  return filename;
};

const DownloadTextReportButton: React.FC<DownloadTextReportButtonProps> = ({
  id,
  short,
  name,
  status,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { auditService } = useBackend();
  const profile = useUser();
  const downloadPDF = useCallback(async () => {
    setLoading(true);
    try {
      await auditService.downloadText(
        id,
        getFilename(name, profile?.reportType, status),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [id, name, status]);
  return (
    <Button variant="success" onClick={downloadPDF} loading={loading}>
      <FaDownload />
      {' '}
      {short ? null : 'Download Text'}
    </Button>
  );
};

export default DownloadTextReportButton;
