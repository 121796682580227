import React, { useEffect, useMemo, useState } from 'react';
import ReactSelect from 'react-select';
import {
  Col, Row, Table
} from 'react-bootstrap';
import { FaPlay, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { isAddress } from 'web3-utils';
import {
  parseDiff,
// @ts-ignore
} from 'react-diff-view';
// @ts-ignore
import { diffLines, formatLines } from 'unidiff';
// @ts-ignore
import Input from '../Input';
import Button from '../Button';
import { getErrorMessage, tokenizeFn } from '../../helpers';
import 'react-diff-view/style/index.css';
import './index.css';
import { useBackend } from '../../providers/BackendProvider';
import {
  BLOCKCHAINS, MAINNET, networks
} from '../../config';
import { CounterResultDto } from '../../dtos/counter-result.dto';
import SliderComponent from '../Slider';
import { ContractWithMatches } from '../../dtos/contract-with-matches.dto';
import ContractList from './ContractList';
import { Texts } from '../../helpers/Texts';

interface ContractStats {
    totalCodeLines?: number;
    uniqueCodeLines?: number;
    comments?: number;
    blankLines?: number;
}

const LineCounterPage: React.FC<{}> = () => {
  const { lineCounterService } = useBackend();
  const [loading, setLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<string>();
  const [netOptions, setNetOptions] = useState<Array<any>>([]);
  const [net, setNet] = useState<string>();
  const [blockchain, setBlockchain] = useState<string>();
  const [isInvalidAddress, setIsInvalidAddress] = useState<boolean>();

  const [includedContracts, setIncludedContracts] = useState<Array<any>>([]);
  const [excludedContracts, setExcludedContracts] = useState<Array<any>>([]);
  const [contractStats, setContractStats] = useState<ContractStats>({});
  const [proxy, setProxy] = useState<{ isProxy: boolean, implementationAddress?: string }>({ isProxy: false });

  const [collapsedContracts, setCollapsedContracts] = useState<any>({});
  const [selectedFiles, setSelectedFiles] = useState<Array<{text: string, file: File}>>([]);
  const [similarityThreshold, setSimilarityThreshold] = useState<number>(0.8);

  const blockchainOptions = useMemo(() => (BLOCKCHAINS), []);

  useEffect(() => {
    if (address) {
      setIsInvalidAddress(!isAddress(address));
    } else {
      setIsInvalidAddress(undefined);
    }
  }, [address]);

  const clearPage = () => {
    setIncludedContracts([]);
    setExcludedContracts([]);
    setContractStats({});
    setProxy({ isProxy: false });
    setCollapsedContracts({});
    setSelectedFiles([]);
    setSimilarityThreshold(0.8);
    setAddress('');
    setNet('');
    setBlockchain('');
  };

  const onFilesAccepted = async (files: File[]) => {
    const texts = Texts.from(files);
    const filesWithText = [];
    let i = 0;
    // eslint-disable-next-line no-restricted-syntax
    for await (const text of texts) {
      filesWithText.push({ text, file: files[i] });
      i += 1;
    }
    setSelectedFiles([...selectedFiles, ...filesWithText]);
  };

  useEffect(() => {
    setNetOptions(blockchain ? networks(blockchain) : []);
    setNet(MAINNET);
  }, [blockchain]);

  const toggleCollapse = (contract: { entitySHA256: string; }) => {
    setCollapsedContracts({
      ...collapsedContracts,
      [contract.entitySHA256]: !collapsedContracts[contract.entitySHA256]
    });
  };

  const collapseAll = () => {
    setCollapsedContracts(excludedContracts.concat(includedContracts).map((contract: { entitySHA256: string; }) => contract.entitySHA256).reduce((acc: any, sha: string) => {
      acc[sha] = true;
      return acc;
    }, {}));
  };

  const expandAll = () => {
    setCollapsedContracts({});
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    validator: (file) => {
      // @ts-ignore
      if (!/.sol$/.test(file.path)) {
        toast.error(() => (
          <text>
            File
            {' '}
            <b>{file.name}</b>
            {' '}
            got wrong extension!
          </text>
        ));
        return {
          code: ErrorCode.FileInvalidType,
          message: 'Wrong file extension'
        };
      }
      if (!(selectedFiles.findIndex((selFile) => selFile.file.name === file.name) === -1)) {
        toast.info(() => (
          <text>
            File
            {' '}
            <b>{file.name}</b>
            {' '}
            already added!
          </text>
        ));
        return {
          code: ErrorCode.FileInvalidType,
          message: 'File already added',
        };
      }
      return null;
    },
    onDropAccepted: onFilesAccepted,
  });

  const onFileRemove = (fileName: string) => {
    const index = selectedFiles.findIndex((selFile) => selFile.file.name === fileName);
    if (index !== -1) {
      const newFiles = [...selectedFiles];
      newFiles.splice(index, 1);
      setSelectedFiles(newFiles);
    }
  };

  const generateContractList = (contracts: Array<ContractWithMatches>) => contracts.map((item) => {
    const exact = item.findMode === 1;
    let bestMatch = null;
    if (!exact) {
      // eslint-disable-next-line prefer-destructuring
      bestMatch = item.matches.sort((a: any, b: any) => b.tunedSimilarity - a.tunedSimilarity)[0];
    }
    if (exact) {
      const releaseItem = item.matches.find((match: any) => match.branch?.includes('release'));
      bestMatch = releaseItem || item.matches[0];
    }
    const result = {
      entitySHA256: item.entitySHA256,
      entityName: item.entityName,
      entityCode: item.entityCode,
      exact,
      bestMatch,
      order: (exact && 1.01) || (bestMatch ? bestMatch.tunedSimilarity : 0),
      codeLines: item.codeLines,
      comments: item.comments,
      blankLines: item.blankLines,
      diff: undefined,
    };
    if (bestMatch) {
      const matchCode = bestMatch.contents.replaceAll('\r', '');
      const strippedEntityCode = item.entityCode.replaceAll('\r', '');
      const lines = diffLines(matchCode, strippedEntityCode);
      const diffText = formatLines(lines, { context: 3 });
      const [diff] = parseDiff(diffText, { nearbySequences: 'zip' });
      result.diff = {
        ...diff,
        tokens: tokenizeFn(diff.hunks, matchCode)
      };
    }
    return result;
  }).sort((a: any, b: any) => a.order - b.order);

  const notEnoughData = (!address || !net || !blockchain) && !selectedFiles.length;

  const checkContract = async () => {
    if (notEnoughData || loading) return;
    setIncludedContracts([]);
    setExcludedContracts([]);
    setCollapsedContracts({});
    setContractStats({});
    setProxy({ isProxy: false });

    const requestByAddress = address && blockchain && net;
    if (requestByAddress) {
      setSelectedFiles([]);
      if (isInvalidAddress) {
        toast.error('Address is invalid!');
        return;
      }
    }

    setLoading(true);

    try {
      let response: CounterResultDto;
      if (requestByAddress) {
        response = await lineCounterService.countLines({
          net: net!,
          blockchain: blockchain!,
          address: address!,
          similarityThreshold,
          type: 'network'
        });
      } else {
        response = await lineCounterService.countLines({
          type: 'text',
          text: selectedFiles.map((selFile) => selFile.text).join('\n'),
          similarityThreshold,
        });
      }

      setExcludedContracts(generateContractList(response.excludedEntities));
      setIncludedContracts(generateContractList(response.includedEntities));
      setContractStats({
        blankLines: response.blankLines,
        comments: response.comments,
        totalCodeLines: response.totalCodeLines,
        uniqueCodeLines: response.uniqueCodeLines
      });
      collapseAll();
      if (response.isProxy) {
        setProxy({
          isProxy: true,
          implementationAddress: response.implementationAddress
        });
      }

      if (!response.excludedEntities.length && !response.includedEntities.length) {
        toast.info('No results found');
      } else {
        toast.success('Success');
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row className="mb-3">
        <Col xs={12} md={4} className="">
          <div className="fc-item-bottom">
            <Input
              placeholder="Enter contract address"
              onChange={(e) => setAddress(e.target.value)}
              value={address || ''}
              isInvalid={isInvalidAddress}
              spellCheck={false}
            />
          </div>
        </Col>
        <Col xs={6} md={4} className="">
          <div className="fc-item-bottom">
            <ReactSelect
              placeholder="Select blockchain"
              options={blockchainOptions}
              value={blockchainOptions.find((item) => item.value === blockchain) || []}
              onChange={(e) => setBlockchain(e?.value)}
              menuPlacement="auto"
              menuPosition="fixed"
            />
          </div>
        </Col>
        <Col xs={6} md={4} className="">
          <div className="fc-item-bottom">
            <ReactSelect
              placeholder="Select Network"
              options={netOptions}
              value={netOptions.find((item) => item.value === net) || ''}
              onChange={(e) => setNet(e?.value!)}
              menuPlacement="auto"
              menuPosition="fixed"
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={4} md={4} className="">
          <div className="slider-container">
            <SliderComponent
              min={0}
              max={1}
              defaultValue={0.8}
              step={0.01}
              value={similarityThreshold}
              onChange={(n) => setSimilarityThreshold(n as number)}
            />
          </div>
          <div className="slider-label">
            Similarity Threshold:
            {' '}
            {similarityThreshold}
            {' '}
            <span className="info-icon">
              ?
              <span className="tooltip-text">
                Lines are unique if similarity of contract is less than threshold
              </span>
            </span>
          </div>
        </Col>
        <Col xs={4} md={1} className="">
          <div className="fc-item-bottom fc-to-right">
            <Button disabled={notEnoughData || isInvalidAddress} loading={loading} onClick={checkContract} className="fc-button">
              <FaPlay />
              {' '}
              Check
            </Button>
          </div>
        </Col>
        <Col xs={4} md={1} className="">
          <div className="fc-item-bottom fc-to-right">
            <Button loading={loading} onClick={clearPage} className="fc-button">
              Clear Results
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <div className="container">
          <div {...getRootProps()} className={`drop-base-styled${isFocused ? ' drop-base-styled__focused' : ''}${isDragAccept ? ' drop-base-styled__accepted' : ''}${isDragReject ? ' drop-base-styled__rejected' : ''}`}>
            <input {...getInputProps()} />
            Or drop .sol files here
          </div>
          <div className="fc-file-list">
            <ul>
              {
                selectedFiles.map((selFile) => (
                  <li key={selFile.file.name}>
                    {selFile.file.name}
                    {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
                    <div role="button" title="Remove file" className="fc-remove-file" onClick={() => onFileRemove(selFile.file.name)} onKeyUp={() => onFileRemove(selFile.file.name)}>
                      <FaTimes color="red" />
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </Row>
      {!includedContracts.length && !excludedContracts.length ? null : (
        <>
          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Table striped>
                <tbody>
                  <tr>
                    <td><b>Total Code Lines</b></td>
                    <td>{contractStats.totalCodeLines}</td>
                  </tr>
                  <tr>
                    <td><b>Unique Code Lines</b></td>
                    <td>{contractStats.uniqueCodeLines}</td>
                  </tr>
                  <tr>
                    <td><b>Blank Lines</b></td>
                    <td>{contractStats.blankLines}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col xs={12} md={6}>
              <Button loading={loading} onClick={collapseAll} className="fc-button">
                Collapse All
              </Button>
              {' '}
              <Button loading={loading} onClick={expandAll} className="fc-button">
                Expand All
              </Button>
            </Col>
            {proxy.isProxy ? (
              <Col xs={12} md={6}>
                Looks like this contract is a proxy. Implementation address:
                {' '}
                <b>{proxy.implementationAddress}</b>
              </Col>
            ) : null}
          </Row>
          <Row className="mb-3">
            <h1 className="title">Included Contracts</h1>
          </Row>
          <ContractList
            contracts={includedContracts}
            toggleCollapse={toggleCollapse}
            collapsedContracts={collapsedContracts}
          />
          <Row className="mb-3">
            <h1 className="title">Excluded Contracts</h1>
          </Row>
          <ContractList
            contracts={excludedContracts}
            toggleCollapse={toggleCollapse}
            collapsedContracts={collapsedContracts}
          />
        </>
      ) }

    </>
  );
};

export default LineCounterPage;
