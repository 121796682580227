import ProtectedService from './ProtectedService';

class AutoAuditService extends ProtectedService {
  baseUrl = '/auto-audits';

  publish = async (id: string) => this.get(null, `${id}/publish`);

  getList = async (
    page: number,
    limit: number,
    sortBy?: string,
    sortDirection?: string,
    filter?: any,
  ) => this.get(
    null,
    `?page=${page}&limit=${limit}${
      sortBy ? `&sort=${sortBy},${sortDirection}` : ''
    }${filter ? `&filter=${JSON.stringify(filter)}` : ''}`,
  );
}

export default AutoAuditService;
