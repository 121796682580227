import React from 'react';
// @ts-ignore
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import vs from './vs';

export interface SourceTabProps {
    sourceCode: string;
    language: string;
}

const SourceCode: React.FC<SourceTabProps> = ({ sourceCode, language }) => (
  <SyntaxHighlighter
    language={language}
    style={vs}
    showLineNumbers
    lineNumberStyle={{ color: 'black' }}
  >
    {sourceCode}
  </SyntaxHighlighter>
);

export default SourceCode;
