import React, { useState } from 'react';
import { FaBookOpen } from 'react-icons/fa';
import { FaTimes } from 'react-icons/all';
import Button from 'components/Button/index';
import { StandardIssue } from '../../../interfaces';
import Popup from '../../Popup/Popup';
import StandardIssuesTable from '../../Table/StandardIssuesTable';

const LoadFromStandardIssuesButton: React.FC<{
  onSelect: (data: StandardIssue) => void;
}> = ({ onSelect }) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const onSelectWrapper = (data: any) => {
    setShowPopup(false);
    onSelect(data);
  };

  const onOpen = (event: any) => {
    event.stopPropagation();
    setShowPopup(true);

    return false;
  };

  return (
    <>
      <Button
        className="right-position-btn"
        size="sm"
        title="Load from standard"
        variant="dark"
        onClick={onOpen}
      >
        <FaBookOpen />
      </Button>
      <Popup showPopup={showPopup} full>
        <StandardIssuesTable selectMode onSelect={onSelectWrapper} />
        <Button
          title="Delete"
          size="sm"
          variant="danger"
          className="m-0 close-popup-btn"
          onClick={() => setShowPopup(false)}
        >
          <FaTimes />
        </Button>
      </Popup>
    </>
  );
};

export default LoadFromStandardIssuesButton;
