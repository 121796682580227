import React from 'react';
import { useFormContext } from 'components/Form/context';
import { Form } from 'react-bootstrap';

// eslint-disable-next-line react/display-name
const Checkbox: React.FC<any> = React.forwardRef<HTMLInputElement, { name: string }>((props, ref) => {
  const { name } = props;
  const { register } = useFormContext();

  return (
    <Form.Check type="checkbox" {...register(name)} ref={ref} style={{ transform: 'scale(1.2)' }} />
  );
});

export default Checkbox;
