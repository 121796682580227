import React, { useEffect, useState } from 'react';
import {
  Collapse,
  Card,
  Col,
  Row,
} from 'react-bootstrap';

import { useFormContext } from 'components/Form/context';
import FormFieldControl from 'components/FormFieldControl';
import { Audit } from '../../interfaces';

const SocialsForm: React.FC<{ audit?: Audit }> = ({ audit }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { collapseAllCb } = useFormContext();

  const toggleCollapse = () => setCollapsed(!collapsed);

  useEffect(() => {
    collapseAllCb(() => {
      setCollapsed(false);
    });
  }, [collapseAllCb]);

  return (
    <Card>
      <Card.Header onClick={toggleCollapse}>
        Additional info
      </Card.Header>
      <Collapse in={collapsed}>
        <Card.Body>
          <Row>
            <Col>
              <FormFieldControl name="projectIcon" defaultValue={audit?.projectIcon} />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormFieldControl name="projectTelegram" defaultValue={audit?.projectTelegram} />
            </Col>
            <Col>
              <FormFieldControl name="projectTwitter" defaultValue={audit?.projectTwitter} />
            </Col>
            <Col>
              <FormFieldControl name="projectLinkedin" defaultValue={audit?.projectLinkedin} />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormFieldControl name="projectMedium" defaultValue={audit?.projectMedium} />
            </Col>
            <Col>
              <FormFieldControl name="projectGithub" defaultValue={audit?.projectGithub} />
            </Col>
            <Col>
              <FormFieldControl name="slug" defaultValue={audit?.slug} />
            </Col>
            <Col>
              <FormFieldControl name="coinCodexSlug" defaultValue={audit?.coinCodexSlug} />
            </Col>
            <Col>
              <FormFieldControl name="projectCoinMarketCap" defaultValue={audit?.projectCoinMarketCap} />
            </Col>
          </Row>
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default SocialsForm;
