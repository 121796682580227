import React, { useMemo, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaRegCopy } from 'react-icons/fa';
import Button from 'components/Button/index';
// @ts-ignore
import Output from 'editorjs-react-renderer';
import { usePublishTemplate } from 'hooks/usePublishTemplate';
import {
  Audit, getClassificatorValue, IssueSeverity, IssueSeverityPrefix
} from '../../../../interfaces';

const RECHECK_DATE = 14;
const criticalSeverity = getClassificatorValue(IssueSeverity, IssueSeverityPrefix.CRITICAL);
const highSeverity = getClassificatorValue(IssueSeverity, IssueSeverityPrefix.HIGH);
const mediumSeverity = getClassificatorValue(IssueSeverity, IssueSeverityPrefix.MEDIUM);
const lowSeverity = getClassificatorValue(IssueSeverity, IssueSeverityPrefix.LOW);
const infoSeverity = getClassificatorValue(IssueSeverity, IssueSeverityPrefix.INFO);

const setTemplateValues = (template: string, audit: any) => {
  const date = new Date();
  const timestamp = date.getTime();
  date.setDate(date.getDate() + RECHECK_DATE);
  const result = template
    .replace('{{recheck_deadline_date}}', date.toLocaleDateString('en-us', {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }));

  const aggregatedIssues: any = {};
  audit.contracts.forEach((contract: { issues: any; name: any; }) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const issue of contract.issues) {
      if (!aggregatedIssues[issue.severity]) {
        aggregatedIssues[issue.severity] = {
          items: [{
            issue,
            contract: contract.name
          }]
        };
      } else {
        aggregatedIssues[issue.severity].items.push({
          issue,
          contract: contract.name
        });
      }
    }
  });
  const criticalIssuesCount = aggregatedIssues[criticalSeverity]?.items.length || 0;
  const highIssuesCount = aggregatedIssues[highSeverity]?.items.length || 0;
  const mediumIssuesCount = aggregatedIssues[mediumSeverity]?.items.length || 0;
  const lowIssuesCount = aggregatedIssues[lowSeverity]?.items.length || 0;
  const infoIssuesCount = aggregatedIssues[infoSeverity]?.items.length || 0;
  const issuesBulletList = [
    criticalIssuesCount ? `{"id":"xL_5PYYvCd_1_${timestamp}","type":"paragraph","data":{"text":"🔴&nbsp;${criticalIssuesCount} critical severity issue${criticalIssuesCount > 1 ? 's' : ''}"}}` : false,
    highIssuesCount ? `{"id":"xL_5PYYvCd_2_${timestamp}","type":"paragraph","data":{"text":"🟠&nbsp;${highIssuesCount} high severity issue${highIssuesCount > 1 ? 's' : ''}"}}` : false,
    mediumIssuesCount ? `{"id":"xL_5PYYvCd_3_${timestamp}","type":"paragraph","data":{"text":"🔵&nbsp;${mediumIssuesCount} medium severity issue${mediumIssuesCount > 1 ? 's' : ''}"}}` : false,
    lowIssuesCount ? `{"id":"xL_5PYYvCd_4_${timestamp}","type":"paragraph","data":{"text":"🟢&nbsp;${lowIssuesCount} low severity issue${lowIssuesCount > 1 ? 's' : ''}"}}` : false,
    infoIssuesCount ? `{"id":"xL_5PYYvCd_5_${timestamp}","type":"paragraph","data":{"text":"⚪ ${infoIssuesCount} info severity issue${infoIssuesCount > 1 ? 's' : ''}"}}` : false,
  ].filter(Boolean);

  if (issuesBulletList.length) {
    return result
      .replace(/{"id":"[^"]*","type":"paragraph","data":{"text":"[^"]*{{issues_bullet_list}}[^"]*"}}/, issuesBulletList.join(' , '));
  }

  return result
    .replace(/{"id":"[^"]*","type":"paragraph","data":{"text":"[^"]*{{issues_bullet_list}}[^"]*"}},?/, '');
};

export function copyToClip(str: any) {
  function listener(e: any) {
    e.clipboardData.setData('text/html', str);
    e.clipboardData.setData('text/plain', str);
    e.preventDefault();
  }
  document.addEventListener('copy', listener);
  document.execCommand('copy');
  document.removeEventListener('copy', listener);
}
interface PublishTemplateProps {
  isHashex: boolean;
  auditStatus: number;
  audit: Audit|null;
}

const PublishTemplate: React.FC<PublishTemplateProps> = ({
  isHashex,
  auditStatus,
  audit
}) => {
  if (!isHashex) return null;
  const { publishTemplate } = usePublishTemplate(auditStatus);
  const parsedTemplate = useMemo(() => {
    if (!publishTemplate) return null;
    const result = setTemplateValues(publishTemplate, audit);
    return JSON.parse(result);
  }, [publishTemplate]);
  const ref = useRef<HTMLDivElement>(null);

  const onCopyClick = () => {
    copyToClip(ref.current?.innerHTML || '');
  };

  return (
    <>
      <div ref={ref}>
        <Output
          data={parsedTemplate}
        />
      </div>
      <Row>
        <Col xs={12} md={12} className="d-flex justify-content-end">
          <Button
            size="sm"
            title="Copy to clipboard"
            variant="success"
            onClick={onCopyClick}
          >
            <FaRegCopy />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PublishTemplate;
