import ProtectedService from './ProtectedService';

class UsersService extends ProtectedService {
    baseUrl = '/users';

    companyUsers = async () => this.get(null, 'by-company')

    createUser = async (login: string, name: string, isAdmin: boolean) => this.post({
      login,
      name,
      isAdmin,
    })

    resetPwd = async (userId: number) => this.post(null, `${userId}/reset-pwd`)

    updateUser = async (userId: number, name: string) => this.patch({
      name,
    }, `${userId}`)

    blockUser = async (userId: number, blocked: boolean) => this.post(null, `${userId}/${blocked ? 'unblock' : 'block'}`)

    unblockUser = async (userId: number) => this.post(null, `${userId}/unblock`)
}

export default UsersService;
