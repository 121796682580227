import React from 'react';
import classnames from 'classnames';

import { Comment } from 'interfaces';

import List, { ListProps } from 'components/List';
import CommentForm from 'forms/Comment';

export interface CommentsListProps
  extends Omit<ListProps<Comment>, 'renderItem'> {}

const CommentsList: React.FC<CommentsListProps> = ({ className, ...props }) => {
  const classNames = classnames('chapters-list', className);
  return (
    <List<Comment>
      className={classNames}
      {...props}
      component={CommentForm}
      addText="Add comment"
    />
  );
};

export default CommentsList;
