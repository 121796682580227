export class Texts implements AsyncIterable<any> {
    private index: number;

    private fileReader: FileReader = new FileReader();

    // eslint-disable-next-line no-useless-constructor
    private constructor(readonly files: File[], readonly encoding: string) {
      this.index = 0;
    }

    public static from(files: File[], encoding = 'UTF-8'): Texts {
      return new Texts(files, encoding);
    }

    private readNextFile(file: File): Promise<string> {
      return new Promise((resolve, reject) => {
        this.fileReader.onloadend = () => resolve(this.fileReader.result as string);
        this.fileReader.onerror = () => reject(new Error('File read error'));
        this.index += 1; // will skip file with error
        this.fileReader.readAsText(file, this.encoding);
      });
    }

    [Symbol.asyncIterator](): AsyncIterator<string> {
      return {
        next: async () => {
          if (this.index < this.files.length) {
            const value = await this.readNextFile(this.files[this.index]);
            return { value, done: false };
          }
          return { done: true, value: null };
        }
      };
    }

  // generator implementation
  // async* [Symbol.asyncIterator](): AsyncGenerator<string> {
  //   while (this.index < this.files.length) {
  //     yield this.readNextFile(this.files[this.index]);
  //   }
  // }
}
