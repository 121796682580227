export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
export const REFRESH_TOKEN_SUB_URL = 'auth/refresh';
export const ACCESS_TOKEN_KEY = 'access_token';

export const BLOCKCHAINS = [
  { value: 'ARBITRUM', label: 'Arbitrum Network' },
  { value: 'AURORA', label: 'Aurora', },
  { value: 'AVALANCHE', label: 'Avalanche Network', },
  {
    value: 'BASE', label: 'Base',
  },
  {
    value: 'BINANCE_SMART_CHAIN',
    label: 'Binance smart chain',
  },
  { value: 'CRONOS', label: 'Cronos', },
  {
    value: 'ETHEREUM',
    label: 'Ethereum mainnet',
  },
  { value: 'FANTOM', label: 'Fantom', },
  { value: 'METIS', label: 'Metis', },
  { value: 'OPTIMISM', label: 'Optimism' },
  { value: 'POLYGON', label: 'Polygon', },
  { value: 'PULSE', label: 'Pulse' },
  { value: 'XDAI', label: 'Gnosis (xDai)', },
];

export const MAINNET = 'MAINNET';
export const TESTNET = 'TEST';

export const networks = (blockchain: string) => {
  switch (blockchain) {
    case 'ETHEREUM':
      return [
        { value: MAINNET, label: 'Mainnet' },
        { value: 'GOERLI', label: 'Goerli' },
        { value: 'KOVAN', label: 'Kovan' },
        { value: 'RINKEBY', label: 'Rinkeby' },
        { value: 'ROPSTEN', label: 'Ropsten' },
        { value: 'SEPOLIA', label: 'Sepolia' },
      ];
    case 'XDAI':
      return [
        { value: MAINNET, label: 'Mainnet' },
      ];
    default:
      return [
        { value: MAINNET, label: 'Mainnet' },
        { value: TESTNET, label: 'Testnet' },
      ];
  }
};
