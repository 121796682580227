import React from 'react';
import { Col } from 'react-bootstrap';
import {
  getClassificatorValue, ReportTemplate, ReportTemplatePrefix
} from 'interfaces';
import FormFieldControl from 'components/FormFieldControl';
import { IssueFormProps } from '../index';

interface CompanyMap {
  [x: number]: (props: IssueFormProps) => JSX.Element | null;
}

const ResolutionField: React.FC<IssueFormProps> = ({ name, value }) => {
  const { resolution } = value;

  return (
    <Col xs={12} md={4}>
      <FormFieldControl name={`${name}.resolution`} defaultValue={resolution} />
    </Col>
  );
};

const resolution: CompanyMap = {
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix.HASHEX)]: ResolutionField,
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix['0XGUARD'])]: () => null,
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix.RUGDOG)]: ResolutionField
};

export default resolution;
