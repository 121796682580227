import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';

import {
  getClassificatorValue,
  Issue,
  Profile,
  ReportTemplate,
  ReportTemplatePrefix,
} from 'interfaces';

import { fields as CommentFields } from 'forms/Comment';

import { FormFields } from 'components/Form';
import FormFieldControl from 'components/FormFieldControl';
import TextEditor from 'components/TextEditor';
import { EnumTypes } from 'services/EnumsService';
import { useEnums } from 'providers/EnumsProvider';

import SeveritySelect from 'components/Select/SeveritySelect';
import ResolutionSelect from 'components/Select/ResolutionSelect';
import CollapsibleItem from 'components/CollapsibleItem';
import Comments from 'components/List/Comments';
import css from './Issue.module.scss';
import { useUser } from '../../providers/UserProvider';
import { useFormContext } from '../../components/Form/context';
import LoadFromStandardIssuesButton from '../../components/Button/LoadFromStandardIssuesButton';
import CreateStandardIssueButton from '../../components/Button/CreateStandardIssueButton';

import Resolution from './fields/Resolution';

const getBadgeBgColor = (severity: number) => {
  switch (severity) {
    case 0: return '#1D3F66';
    case 1: return '#70D4FF';
    case 2: return '#B04FFD';
    case 3: return '#FF9029';
    case 4: return '#FF2358';
    default: return '#FFDBDB';
  }
};

const getStatusBadgeBgColor = (status: number) => {
  switch (status) {
    case 0: return '#FD524F';
    case 1: return '#70D4FF';
    case 2: return '#1AC949';
    case 3: return '#ffaf38';
    case 4: return '#FF2358';
    default: return '#FFDBDB';
  }
};

export const fields: FormFields = {
  severity: {
    label: 'Severity',
    component: SeveritySelect,
  },
  resolution: {
    label: 'Resolution',
    component: ResolutionSelect,
  },
  name: {
    label: 'Issue title',
  },
  description: {
    label: 'Description',
    component: TextEditor,
  },
  recommendation: {
    label: 'Recommendation',
    component: TextEditor,
  },
  comment: {
    label: 'Issue comments',
    component: Comments,
    fields: CommentFields,
  },
  standard: {
    label: 'Is standard',
  },
};

export interface IssueFormProps {
  name: string;
  value: Issue;
}

const columnsNumber: {
  [x: number]: number
} = {
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix.HASHEX)]: 4,
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix['0XGUARD'])]: 4,
  [getClassificatorValue(ReportTemplate, ReportTemplatePrefix.RUGDOG)]: 4
};

const getColummsNumber = (profile: Profile | null) => {
  if (!profile) {
    return 4;
  }

  return columnsNumber[profile?.reportType];
};

const IssueForm: React.FC<IssueFormProps> = ({ name, value }) => {
  const [collapsed, setCollapsed] = useState<boolean>(!value.name);
  const profile = useUser();
  const { filterEnums } = useEnums();
  const {
    watch, setValue, getValues, setTextEditorValue, collapseAllCb
  } = useFormContext();
  const watchType = watch('type');
  const is0xGuardType = profile?.reportType
    === getClassificatorValue(ReportTemplate, ReportTemplatePrefix['0XGUARD']);

  const onSelect = (data: any) => {
    setValue(`${name}.name`, data.name, { shouldDirty: true });
    setValue(`${name}.severity`, data.severity, { shouldDirty: true });
    setValue(`${name}.resolution`, data.resolution, { shouldDirty: true });
    setTextEditorValue(`${name}.description`, data.description, {
      shouldDirty: true,
    });
    setValue(`${name}.standard`, true, { shouldDirty: true });
  };

  const getDefault = () => ({
    name: getValues(`${name}.name`),
    severity: getValues(`${name}.severity`),
    description: getValues(`${name}.description`),
  });

  const severity = useMemo(() => {
    if (value.severity === null) {
      return null;
    }

    return filterEnums(EnumTypes.ISSUE_SEVERITY).find((type) => type.key === value.severity)?.value;
  }, [value.severity]);

  const status = useMemo(() => {
    if (value.resolution === null) {
      return null;
    }
    return filterEnums(EnumTypes.ISSUE_STATUS).find((type) => type.key === value.resolution)?.value;
  }, [value.resolution]);

  useEffect(() => {
    collapseAllCb(() => {
      setCollapsed(false);
    });
  }, [collapseAllCb]);

  const ResolutionField = useMemo(() => {
    if (!profile) {
      return null;
    }

    return Resolution[profile?.reportType];
  }, [profile]);

  return (
    <CollapsibleItem
      title={
        `${value.name}${value.standard ? ' (standard)' : ''}`
        || 'Enter issue title'
      }
      in={collapsed}
      icon={<LoadFromStandardIssuesButton onSelect={onSelect} />}
      icon2={<CreateStandardIssueButton getDefault={getDefault} />}
      badge={<Badge bg="none" className={css.badge} style={{ background: getBadgeBgColor(value.severity) }}>{severity}</Badge>}
      badge2={is0xGuardType ? <Badge bg="none" className={css.badge} style={{ background: getStatusBadgeBgColor(value.resolution) }}>{status}</Badge> : null}
      className={css.cardBody}
    >
      <>
        <Row>
          <Col xs={12} md={getColummsNumber(profile)}>
            <FormFieldControl name={`${name}.name`} defaultValue={value.name} />
          </Col>
          <Col xs={12} md={getColummsNumber(profile)}>
            <FormFieldControl
              name={`${name}.severity`}
              defaultValue={value.severity}
            />
          </Col>
          {is0xGuardType && (
            <Col xs={12} md={getColummsNumber(profile)}>
              <FormFieldControl
                name={`${name}.resolution`}
                defaultValue={value.resolution}
              />
            </Col>
          )}
          {ResolutionField && <ResolutionField name={name} value={value} />}
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <FormFieldControl
              name={`${name}.description`}
              defaultValue={value.description}
              isFocused
            />
          </Col>
          {is0xGuardType && watchType !== 0 ? (
            <Col xs={12} md={12}>
              <FormFieldControl
                name={`${name}.recommendation`}
                defaultValue={value.recommendation}
              />
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col>
            <FormFieldControl
              name={`${name}.comment`}
              defaultValue={value.comment}
            />
          </Col>
        </Row>
      </>
    </CollapsibleItem>
  );
};

export default IssueForm;
