import React, { useEffect, useState, ReactNode } from 'react';
import { Card, Collapse, CollapseProps } from 'react-bootstrap';

import { useFormContext } from 'components/Form/context';

interface CollapsibleItemProps extends CollapseProps {
  title: any;
  in?: boolean;
  icon?: any;
  icon2?: any;
  badge?: ReactNode;
  badge2?: ReactNode;
}

const CollapsibleItem: React.FC<CollapsibleItemProps> = ({
  title,
  icon,
  icon2,
  badge,
  badge2,
  children,
  in: forceIn,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(!!forceIn);
  const toggleCollapse = () => setCollapsed(!collapsed);
  const { collapseAllCb } = useFormContext();

  useEffect(() => {
    collapseAllCb(() => {
      setCollapsed(false);
    });
  }, [collapseAllCb]);

  return (
    <Card>
      <Card.Header onClick={toggleCollapse}>
        {title}
        {badge}
        {badge2}
        {collapsed && icon ? icon : ''}
        {collapsed && icon2 ? icon2 : ''}
      </Card.Header>
      <Collapse {...props} in={collapsed}>
        <Card.Body>{children}</Card.Body>
      </Collapse>
    </Card>
  );
};

export default CollapsibleItem;
