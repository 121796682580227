import React, { useMemo } from 'react';
import { Col, Row, CloseButton } from 'react-bootstrap';
import * as yup from 'yup';
import SubmitButton from 'components/Button/SubmitButton';
import Form, { FormField } from 'components/Form';
import { fields as CommentFields } from 'forms/Comment';
import FormFieldControl from 'components/FormFieldControl';
import Comments from 'components/List/Comments';
import SeveritySelect from 'components/Select/SeveritySelect';
import ResolutionSelect from 'components/Select/ResolutionSelect';
import { Issue } from 'interfaces';
import IsStandardIssueCheckbox from 'components/Checkbox/IsStandardIssueCheckbox';
import TextEditor from 'components/TextEditor';
import { useUser } from '../../providers/UserProvider';

import Recomendation from './fields/Recomendation';

interface IssueFormProps {
  onCreateClose: any;
  onSubmit: any;
  state?: Issue;
  isSubmitting?: boolean;
}

const getFields = (): { [x: string]: FormField } => ({
  name: {
    label: 'Title',
    validator: yup.string().required()
  },
  severity: {
    label: 'Severity',
    component: SeveritySelect,
    validator: yup.number().required()
  },
  resolution: {
    label: 'Resolution',
    component: ResolutionSelect,
    validator: yup.number().required()
  },
  standard: {
    component: IsStandardIssueCheckbox,
    validator: yup.boolean().required()
  },
  description: {
    label: 'Description',
    component: TextEditor
  },
  recommendation: {
    label: 'Recommendation',
    component: TextEditor,
  },
  comment: {
    label: 'Comment',
    component: Comments,
    fields: CommentFields
  }
});

const getDefault = (): {
  name?: string;
  severity?: number;
  resolution?: number;
  contractType?: number;
  description?: any;
  standard?: boolean;
  recommendation?: any;
  comment?: any;
} => ({});

const EditIssueForm: React.FC<IssueFormProps> = ({
  onSubmit, onCreateClose, state = getDefault(), isSubmitting
}) => {
  const fields = useMemo(() => getFields(), []);
  const profile = useUser();

  const RecomendationField = useMemo(() => {
    if (!profile) {
      return null;
    }

    return Recomendation[profile?.reportType];
  }, [profile]);

  return (
    <Form
      onSubmit={onSubmit}
      fields={fields}
      defaultValues={state}
      // silent={silent}
    >
      <Row className="mb-3">
        <Col xs={12} md={12} className="d-flex justify-content-end">
          <CloseButton onClick={onCreateClose} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormFieldControl name="name" defaultValue={state.name} />
        </Col>
        <Col>
          <FormFieldControl name="severity" defaultValue={state.severity} />
        </Col>
        <Col>
          <FormFieldControl name="resolution" defaultValue={state.resolution} />
        </Col>
      </Row>
      <Col>
        <FormFieldControl name="standard" defaultValue={state.standard} />
      </Col>
      <Row>
        <Col>
          <FormFieldControl name="description" defaultValue={state.description} isFocused />
        </Col>
      </Row>
      {RecomendationField && <RecomendationField state={state} />}
      <Row>
        <Col>
          <FormFieldControl name="comment" defaultValue={state.comment} />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={12} md={12} className="d-flex justify-content-end">
          <SubmitButton loading={isSubmitting} className="btn-success m-0">
            Save
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
};

export default EditIssueForm;
